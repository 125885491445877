import { API } from "aws-amplify";
import { getUserId } from "./user";

export async function createStudent(student) {
  return await API.post("ilingu", "/students", {
    body: student
  });
}
export async function getStudent(studentId) {
  try {
    return await API.get("ilingu", `/students/${studentId}`, {})
  } catch (e) {
    console.log(e);
    return e;
  }
}
export async function getStudentFromUser() {
  try {
    let userId = await getUserId();
    return await getStudent(userId);
  } catch (e) {
    console.log(e);
    return e;
  }
}
export async function updateStudent(userId, data) {
  return await API.put("ilingu", `/students/${userId}`, {
    body: data
  });
}
export async function updateStudentFull(student) {
  return await API.put("ilingu", `students/${student.userId}/updatefull`, {
    body: student
  })
}