const dev = {
  amplify: {
    Auth: {
      mandatorySignIn: false,
      region: "ap-northeast-1",
      userPoolId: "ap-northeast-1_rWZ03rvWn",
      userPoolWebClientId: "36cbbcrmpum9t6c4umakbpcc4i",
      identityPoolId: "ap-northeast-1:d16d29c9-29a4-47aa-9f2c-b75c3444d204",
      cookieStorage: {
        domain: "localhost",
        path: "/",
        expires: 365,
        secure: false,
      },
    },
    Storage: {
      AWSS3: {
        bucket: "dev-mediafiles-bucket",
        region: "ap-northeast-1"
      },
    },
    API: {
      endpoints: [
        {
          name: "ilingu",
          endpoint: "https://96yc550phf.execute-api.ap-northeast-1.amazonaws.com/dev",
          region: "ap-northeast-1",
        },
      ]
    },
    Analytics: {
      // OPTIONAL - disable Analytics if true
      disabled: false,
      // OPTIONAL - Allow recording session events. Default is true.
      autoSessionRecord: true,

      AWSPinpoint: {
        // OPTIONAL -  Amazon Pinpoint App Client ID
        appId: '294520fa0e694ffdabc0c57500fccb8c',
        // OPTIONAL -  Amazon service region
        region: 'eu-central-1',

        // Buffer settings used for reporting analytics events.
        // OPTIONAL - The buffer size for events in number of items.
        bufferSize: 1000,

        // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
        flushInterval: 5000, // 5s

        // OPTIONAL - The number of events to be deleted from the buffer when flushed.
        flushSize: 100,

        // OPTIONAL - The limit for failed recording retries.
        resendLimit: 5
      },
    },
  },
  stripeKey: "pk_test_C2fMeiRfAmNwsqPy2WfvBErD",
  sentry: {
    dsn: 'https://e7be128932fe4d36b3dc284cf7ca6195@sentry.io/1302901',
    environment: 'dev'
  },
  googleAnalyticsCode: 'UA-71956597-2'
};

export const test = {
  amplify: {
    Auth: {
      mandatorySignIn: false,
      region: "ap-northeast-1",
      userPoolId: "ap-northeast-1_VBdvW09wo",
      userPoolWebClientId: "5m70ojqonuh54mo7defgdjroec",
      identityPoolId: "ap-northeast-1:e91fb7a6-d582-42ee-8b80-917244e1610f",
      cookieStorage: {
        domain: "localhost",
        path: "/",
        expires: 365,
        secure: false,
      },
    },
    Storage: {
      AWSS3: {
        bucket: "testing-mediafiles-bucket",
        region: "ap-northeast-1"
      },
    },
    API: {
      endpoints: [
        {
          name: "ilingu",
          endpoint: "https://mbt7kj8gi5.execute-api.ap-northeast-1.amazonaws.com/testing",
          region: "ap-northeast-1",
        },
      ]
    },
    Analytics: {
      // OPTIONAL - disable Analytics if true
      disabled: false,
      // OPTIONAL - Allow recording session events. Default is true.
      autoSessionRecord: true,

      AWSPinpoint: {
        // OPTIONAL -  Amazon Pinpoint App Client ID
        appId: '294520fa0e694ffdabc0c57500fccb8c',
        // OPTIONAL -  Amazon service region
        region: 'eu-central-1',

        // Buffer settings used for reporting analytics events.
        // OPTIONAL - The buffer size for events in number of items.
        bufferSize: 1000,

        // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
        flushInterval: 5000, // 5s

        // OPTIONAL - The number of events to be deleted from the buffer when flushed.
        flushSize: 100,

        // OPTIONAL - The limit for failed recording retries.
        resendLimit: 5
      },
    },
  },
  stripeKey: "pk_test_C2fMeiRfAmNwsqPy2WfvBErD",
  sentry: {
    dsn: 'https://e7be128932fe4d36b3dc284cf7ca6195@sentry.io/1302901',
    environment: 'test'
  },
  googleAnalyticsCode: 'UA-71956597-2'
};

const prod = {
  amplify: {
    Auth: {
      mandatorySignIn: false,
      region: "ap-northeast-1",
      userPoolId: "ap-northeast-1_kysXpg2rE",
      userPoolWebClientId: "lpv623fle572qohers6cit86j",
      identityPoolId: "ap-northeast-1:ff7c4c73-c653-4e28-98c0-10d6a46a0403",
      cookieStorage: {
        domain: ".ilingu.com",
        path: "/",
        expires: 365,
        secure: true,
      },
    },
    Storage: {
      AWSS3: {
        bucket: "production-mediafiles-bucket",
        region: "ap-northeast-1"
      },
    },
    API: {
      endpoints: [
        {
          name: "ilingu",
          endpoint: "https://1ms6gh5vg7.execute-api.ap-northeast-1.amazonaws.com/production",
          region: "ap-northeast-1",
        },
      ]
    },
    Analytics: {
      // OPTIONAL - disable Analytics if true
      disabled: false,
      // OPTIONAL - Allow recording session events. Default is true.
      autoSessionRecord: true,

      AWSPinpoint: {
        // OPTIONAL -  Amazon Pinpoint App Client ID
        appId: '6dced5cf167c4c7abac89037d5e66078',
        // OPTIONAL -  Amazon service region
        region: 'eu-central-1',

        // Buffer settings used for reporting analytics events.
        // OPTIONAL - The buffer size for events in number of items.
        bufferSize: 1000,

        // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
        flushInterval: 5000, // 5s

        // OPTIONAL - The number of events to be deleted from the buffer when flushed.
        flushSize: 100,

        // OPTIONAL - The limit for failed recording retries.
        resendLimit: 5
      },
    },
  },
  s3: {
    REGION: "ap-northeast-1",
    BUCKET: "production-mediafiles-bucket"
  },
  apiGateway: {
    REGION: "ap-northeast-1",
    URL: "https://1ms6gh5vg7.execute-api.ap-northeast-1.amazonaws.com/production"
  },
  cognito: {
    REGION: "ap-northeast-1",
    USER_POOL_ID: "ap-northeast-1_kysXpg2rE",
    APP_CLIENT_ID: "lpv623fle572qohers6cit86j",
    IDENTITY_POOL_ID: "ap-northeast-1:ff7c4c73-c653-4e28-98c0-10d6a46a0403"
  },
  domain: {
    name: ".ilingu.com",
    secure: true
  },
  sentry: {
    dsn: 'https://e7be128932fe4d36b3dc284cf7ca6195@sentry.io/1302901',
    environment: 'production'
  },
  stripeKey: "pk_live_bapFahUOFheKhdPSjkMBZS4j",
  googleAnalyticsCode: 'UA-71956597-1'
};

let config;
switch (process.env.REACT_APP_STAGE) {
  case 'production':
    config = prod;
    break;
  case 'staging':
    config = {
      ...dev,
      amplify: {
        ...dev.amplify,
        Auth: {
          ...dev.amplify.Auth,
          cookieStorage: {
            domain: ".ilingu.com",
            path: "/",
            expires: 365,
            secure: true,
          },
        }
      },
      sentry: {
        dsn: 'https://e7be128932fe4d36b3dc284cf7ca6195@sentry.io/1302901',
        environment: 'staging'
      },
    };
    break;
  case 'test':
    config = test;
    break;
  case 'dev':
    config = dev;
    break;
  default:
    console.log('Can not find the configuration', process.env);
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  GOE_SERVICE_URL: "https://api.ipstack.com/check?access_key=da733e42e619dda490ed74eef4276455",
  PAGE_SIZE: 100,
  cloudinary: {
    cloud_name: 'ilingu',
    base_url: 'https://res.cloudinary.com/dnmujb2wk'
  },
  ...config
};
