export const GET_ERROR_MESSAGE = "GET_ERROR_MESSAGE";
export const CLEAR_ERROR_MESSAGE = "CLEAR_ERROR_MESSAGE";

// userInfo
export const REQUEST_STUDENT = "REQUEST_STUDENT";
export const RECEIVE_STUDENT = "RECEIVE_STUDENT";
export const REQUEST_ADDRESSES = "REQUEST_ADDRESSES";
export const RECEIVE_ADDRESSES = "RECEIVE_ADDRESSES";
export const CHANGING_DEFAULT_ADDRESSES = "CHANGING_DEFAULT_ADDRESSES";
export const CHANGED_DEFAULT_ADDRESSES = "CHANGED_DEFAULT_ADDRESSES";
export const REQUEST_AVATAR_URL = "REQUEST_AVATAR_URL";
export const RECEIVE_AVATAR_URL = "RECEIVE_AVATAR_URL";
export const UPLOADING_AVATAR = "UPLOADING_AVATAR";
export const UPLOADED_AVATAR = "UPLOADED_AVATAR";
export const UPDATE_TIMEZONE = "UPDATE_TIMEZONE";
export const UPDATED_TIMEZONE = "UPDATED_TIMEZONE";
export const ADD_ADDRESS_START = "ADD_ADDRESS_START";
export const ADD_ADDRESS_RETURN = "ADD_ADDRESS_RETURN";
export const UPDATE_STUDENT_FULL_START = "UPDATE_STUDENT_FULL_START";
export const UPDATE_STUDENT_FULL_RETURN = "UPDATE_STUDENT_FULL_RETURN";
export const UPDATE_STUDENT_SECTION_START = "UPDATE_STUDENT_SECTION_START";
export const UPDATE_STUDENT_SECTION_RETURN = "UPDATE_STUDENT_SECTION_RETURN";
export const GET_IP_STACK_DATA_START = "GET_IP_STACK_DATA_START";
export const GET_IP_STACK_DATA_RETURN = "GET_IP_STACK_DATA_RETURN";
export const SET_DISPLAY_CURRENCY = "SET_DISPLAY_CURRENCY";
export const SET_USER_IS_AUTHENTICATED = "SET_USER_IS_AUTHENTICATED";

// financeInfo
export const REQUEST_BILLING_PROFILE = "REQUEST_BILLING_PROFILE";
export const RECEIVE_BILLING_PROFILE = "RECEIVE_BILLING_PROFILE";
export const UPDATE_BILLING_PROFILE_START = "UPDATE_BILLING_PROFILE_START";
export const UPDATE_BILLING_PROFILE_RETURN = "UPDATE_BILLING_PROFILE_RETURN";
export const REQUEST_SOURCES = "REQUEST_SOURCES";
export const RECEIVE_SOURCES = "RECEIVE_SOURCES";
export const ATTACHING_SOURCE = "ATTACHING_SOURCE";
export const ATTACHED_SOURCE = "ATTACHED_SOURCE";
export const DELETING_SOURCE = "DELETING_SOURCE";
export const DELETED_SOURCE = "DELETED_SOURCE";
export const CHANGING_DEFAULT_SOURCE = "CHANGING_DEFAULT_SOURCE";
export const CHANGED_DEFAULT_SOURCE = "CHANGED_DEFAULT_SOURCE";
export const REQUEST_PURCHASES_START = "REQUEST_PURCHASES_START";
export const REQUEST_PURCHASES_RETURN = "REQUEST_PURCHASES_RETURN";
export const LIST_EXCHANGE_RATES_START = "LIST_EXCHANGE_RATES_START";
export const LIST_EXCHANGE_RATES_RETURN = "LIST_EXCHANGE_RATES_RETURN";

// courseInfo
export const REQUEST_COURSES = "REQUEST_COURSES";
export const RECEIVE_COURSES = "RECEIVE_COURSES";
export const REQUEST_ENROLMENTS = "REQUEST_ENROLMENTS";
export const RECEIVE_ENROLMENTS = "RECEIVE_ENROLMENTS";
export const ADDING_TRIAL_QUESTIONNAIRE = "ADDING_TRIAL_QUESTIONNAIRE";
export const ADDED_TRIAL_QUESTIONNAIRE = "ADDED_TRIAL_QUESTIONNAIRE";
export const REQUEST_PRODUCTS = "REQUEST_PRODUCTS";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const REQUEST_TEACHERS = "REQUEST_TEACHERS";
export const RECEIVE_TEACHERS = "RECEIVE_TEACHERS";
export const CREATING_COURSE = "CREATING_COURSE";
export const CREATED_COURSE = "CREATED_COURSE";
export const CREATING_ENROLMENT = "CREATING_ENROLMENT";
export const CREATED_ENROLMENT = "CREATED_ENROLMENT";
export const CREATING_LESSON = "CREATING_LESSON";
export const CREATED_LESSON = "CREATED_LESSON";
export const CANCEL_LESSON_START = "CANCEL_LESSON_START";
export const CANCEL_LESSON_RETURN = "CANCEL_LESSON_RETURN";
export const REQUEST_TEACHER_LESSONS = "REQUEST_TEACHER_LESSONS";
export const RECEIVE_TEACHER_LESSONS = "RECEIVE_TEACHER_LESSONS";
export const REQUEST_TEACHER_AVAILABILITIES = "REQUEST_TEACHER_AVAILABILITIES";
export const RECEIVE_TEACHER_AVAILABILITIES = "RECEIVE_TEACHER_AVAILABILITIES";
export const LIST_COURSES_BY_STUDENT_ID_START = "LIST_COURSES_BY_STUDENT_ID_START";
export const LIST_COURSES_BY_STUDENT_ID_RETURN = "LIST_COURSES_BY_STUDENT_ID_RETURN";
export const LIST_ENROLMENTS_BY_STUDENT_ID_START = "LIST_ENROLMENTS_BY_STUDENT_ID_START";
export const LIST_ENROLMENTS_BY_STUDENT_ID_RETURN = "LIST_ENROLMENTS_BY_STUDENT_ID_RETURN";
export const LIST_LESSONS_BY_STUDENT_ID_START = "LIST_LESSONS_BY_STUDENT_ID_START";
export const LIST_LESSONS_BY_STUDENT_ID_RETURN = "LIST_LESSONS_BY_STUDENT_ID_RETURN";
export const UPDATE_NOTIFICATION_PREFERENCES_START = "UPDATE_NOTIFICATION_PREFERENCES_START";
export const UPDATE_NOTIFICATION_PREFERENCES_RETURN = "UPDATE_NOTIFICATION_PREFERENCES_RETURN";