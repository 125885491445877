import {
  ADD_ADDRESS_RETURN,
  ADD_ADDRESS_START,
  CHANGED_DEFAULT_ADDRESSES,
  CHANGING_DEFAULT_ADDRESSES,
  GET_IP_STACK_DATA_RETURN,
  GET_IP_STACK_DATA_START,
  RECEIVE_ADDRESSES,
  RECEIVE_AVATAR_URL,
  RECEIVE_STUDENT,
  REQUEST_ADDRESSES,
  REQUEST_AVATAR_URL,
  REQUEST_STUDENT,
  SET_DISPLAY_CURRENCY,
  SET_USER_IS_AUTHENTICATED,
  UPDATE_STUDENT_FULL_RETURN,
  UPDATE_STUDENT_FULL_START,
  UPDATE_STUDENT_SECTION_RETURN,
  UPDATE_STUDENT_SECTION_START,
  UPDATE_TIMEZONE,
  UPDATED_TIMEZONE,
  UPLOADED_AVATAR,
  UPLOADING_AVATAR
} from "../action-types";

export default function getUserInfo(
  state = {
    addresses: [],
    ipStackData: {},
    student: {},
    displayCurrency: "GBP",
    isAuthenticated: false,
    isAddingAddress: false,
    isLoadingAddresses: false,
    isLoadingStudent: false,
    changingToDefaultAddressId: undefined
  },
  action
) {
  switch (action.type) {
    case SET_USER_IS_AUTHENTICATED:
      return Object.assign({}, state, {
        isAuthenticated: action.isAuthenticated
      });
    case SET_DISPLAY_CURRENCY:
      return Object.assign({}, state, {
        displayCurrency: action.displayCurrency,
      });
    case GET_IP_STACK_DATA_START:
      return Object.assign({}, state, {
        isLoadingIpStackData: action.isLoadingIpStackData
      });
    case GET_IP_STACK_DATA_RETURN:
      // Analytics.updateEndpoint({
      //   location: {
      //     city: action.ipStackData.city, // The city where the endpoint is located.
      //     country: action.ipStackData.country_code, // The two-letter code for the country or region of the endpoint. Specified as an ISO 3166-1 alpha-2 code, such as "US" for the United States.
      //     latitude: action.ipStackData.latitude, // The latitude of the endpoint location, rounded to one decimal place.
      //     longitude: action.ipStackData.longitude, // The longitude of the endpoint location, rounded to one decimal place.
      //     postalCode: action.ipStackData.zip, // The postal code or zip code of the endpoint.
      //     region: action.ipStackData.region_name // The region of the endpoint location. For example, in the United States, this corresponds to a state.
      //   },
      // });
      return Object.assign({}, state, {
        isLoadingIpStackData: action.isLoadingIpStackData,
        ipStackData: action.ipStackData,
      });
    case UPDATE_STUDENT_SECTION_START:
      return Object.assign({}, state, {
        isLoadingUpdateStudent: action.isLoadingUpdateStudent
      });
    case UPDATE_STUDENT_SECTION_RETURN:
      return Object.assign({}, state, {
        isLoadingUpdateStudent: action.isLoadingUpdateStudent,
        student: action.student,
      });
    case UPDATE_STUDENT_FULL_START:
      return Object.assign({}, state, {
        isLoadingUpdateStudent: action.isLoadingUpdateStudent
      });
    case UPDATE_STUDENT_FULL_RETURN:
      return Object.assign({}, state, {
        isLoadingUpdateStudent: action.isLoadingUpdateStudent,
        student: action.student,
      });
    case UPDATE_TIMEZONE:
      return Object.assign({}, state, {
        isUpdatingTimezone: action.isUpdatingTimezone
      });
    case UPDATED_TIMEZONE:
      return Object.assign({}, state, {
        isUpdatingTimezone: action.isUpdatingTimezone,
        student: action.student,
      });
    case REQUEST_STUDENT:
      return Object.assign({}, state, {
        isLoadingStudent: action.isLoadingStudent
      });
    case RECEIVE_STUDENT:
      return Object.assign({}, state, {
        student: action.student,
        isLoadingStudent: action.isLoadingStudent
      });
    case REQUEST_AVATAR_URL:
      return Object.assign({}, state, {
        isLoadingAvatarURL: action.isLoadingAvatarURL
      });
    case RECEIVE_AVATAR_URL:
      return Object.assign({}, state, {
        avatarURL: action.avatarURL,
        isLoadingAvatarURL: action.isLoadingAvatarURL
      });
    case UPLOADING_AVATAR:
      return Object.assign({}, state, {
        isUploadingAvatar: action.isUploadingAvatar
      });
    case UPLOADED_AVATAR:
      return Object.assign({}, state, {
        isUploadingAvatar: action.isUploadingAvatar
      });
    case REQUEST_ADDRESSES:
      return Object.assign({}, state, {
        isLoadingAddresses: action.isLoadingAddresses
      });
    case RECEIVE_ADDRESSES:
      return Object.assign({}, state, {
        addresses: action.addresses,
        isLoadingAddresses: action.isLoadingAddresses
      });
    case CHANGING_DEFAULT_ADDRESSES:
      return Object.assign({}, state, {
        changingToDefaultAddressId: action.changingToDefaultAddressId
      });
    case CHANGED_DEFAULT_ADDRESSES:
      return Object.assign({}, state, {
        changingToDefaultAddressId: undefined
      });
    case ADD_ADDRESS_START:
    case ADD_ADDRESS_RETURN:
      return Object.assign({}, state, {
        isAddingAddress: action.isAddingAddress
      });
    default:
      return state;
  }
}
