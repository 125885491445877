import { API } from "aws-amplify";

function TrialQuestionnaireException(message) {
  this.message = message;
  this.name = "trialQuestionnaire Exception"
}

export async function createTrailClassQuestionnaire(data) {
  if (!data.studentId) {
    throw new TrialQuestionnaireException("No studentId")
  } else if (!data.studyHistory) {
    throw new TrialQuestionnaireException("No studyHistory")
  } else if (!data.timezone) {
    throw new TrialQuestionnaireException("No timezone")
  } else if (!data.age) {
    throw new TrialQuestionnaireException("No age")
  } else if (!data.otherLanguages) {
    throw new TrialQuestionnaireException("No otherLanguages")
  } else if (!data.motivation) {
    throw new TrialQuestionnaireException("No motivation")
  }
  try {
    return await API.post("ilingu", `/courses/trial-questionnaire/`, {
      body: {
        ...data,
      }
    });
  } catch (e) {
    console.log(e)
  }
}