import { API, Auth } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import config from "../../config";

Sentry.init(config.sentry);

export async function getUserId() {
  try {
    let user = await Auth.currentAuthenticatedUser();
    // console.log("user:", user);
    return user.username;
  } catch (e) {
    if (e !== "not authenticated") {
      Sentry.withScope(scope => {
        scope.setTag("file", "src/libs/ilingu-lib/user.js:12");
        Sentry.captureException(e);
      });
    }
    // console.log("getUserId failed with error: ", e);
  }
}

export async function createNewUser(email, password) {
  try {
    return await Auth.signUp({
      username: email,
      password: password
    });
  } catch (e) {
    console.log(e);
    Sentry.withScope(scope => {
      scope.setTag("file", "src/libs/ilingu-lib/user.js:31");
      Sentry.captureException(e);
    });
    return e
  }
}


export async function updateZohoBooksContact(zohoContactID, student, address) {
  try {
    let data = {
      contact_name: student.studentName.concat(" - ", student.userId),
      billing_address: {
        address: `${address.addressLine1} ${address.addressLine2}
       ${address.addressLine3}`,
        city: address.city,
        state: address.state,
        zip: address.postCode,
        country: address.country
      }
    };
    return await API.put("ilingu", `/zoho-book/contacts/${zohoContactID}`, {
      body: data
    });
  } catch (e) {
    Sentry.withScope(scope => {
      scope.setTag("file", "src/libs/ilingu-lib/user.js:57");
      Sentry.captureException(e);
    })
  }
}
