import { API } from "aws-amplify";

function EnrolmentException(message) {
  this.message = message;
  this.name = "Enrolment Exception"
}

export async function createEnrolment(enrolment, currency) {
  if (!enrolment.courseId) {
    throw new EnrolmentException("Create enrolment failed. No course ID", enrolment)
  } else if (!enrolment.productId) {
    throw new EnrolmentException("Create enrolment failed. No product ID", enrolment)
  } else if (!enrolment.studentId) {
    throw new EnrolmentException("Create enrolment failed. No Student ID", enrolment)
  } else if (!enrolment.purchasedLessons) {
    throw new EnrolmentException("Create enrolment failed. No purchased lessons (quantity)", enrolment)
  } else if (!enrolment.paymentMethod) {
    throw new EnrolmentException("Create enrolment failed. No payment method", enrolment)
  } else {
    try {
      // set required attributes
      let body = {
        courseId: enrolment.courseId,
        productId: enrolment.productId,
        studentId: enrolment.studentId,
        purchasedLessons: enrolment.purchasedLessons,
        paymentMethod: enrolment.paymentMethod,
      };
      // set optional attributes
      if (enrolment.discount) {
        body.discount = enrolment.discount
      }
      if (enrolment.expiryDate) {
        body.expiryDate = enrolment.expiryDate
      }

      return await API.post("ilingu", "/enrolments", {
        queryStringParameters: {
          currency: currency
        },
        body: body
      });
    } catch (e) {
      console.log(e);
    }
  }
}

export async function listEnrolmentsByStudentId(studentId, params) {
  try {
    return await API.get("ilingu", `/students/${studentId}/enrolments`, {params});
  } catch (e) {
    console.log(e);
    return e
  }
}

//raise Exception without `try catch`, so dispatch it in action
export async function addTrialQuestionnaire(params) {
  return await API.post("ilingu", "/enrolments/trial-questionnaire", {
    body: params
  });
}
