import React, { Fragment } from "react";
import { instanceOf, PropTypes } from "prop-types";
import { Cookies, withCookies } from "react-cookie";
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import Button from "../CustomButtons/Button.jsx";
// custom components
import RegistrationForm from "./RegistrationForm";
import ConfirmForm from "./ConfirmForm";

import signupPageStyle from "../../assets/jss/ilingu/views/signupPageStyle";

const styles = {
  ...signupPageStyle,
  errorMsg: {
    color: "#f44336"
  },
  resendCodeLink: {
    cursor: "pointer",
    fontSize: "12px"
  },
  resendResult: {
    fontSize: "12px",
    color: "red"
  }
};

class SignUpStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showRegDialog: true,
      display: "",
      payload: {
        studentName: "",
        chineseName: "",
        regEmail: "",
        password: "",
        confirmPassword: "",
        agreeTerms: false,
        mailingList: false,
      },
      formIsValid: false,
      confirmForm: {
        code: ""
      },
      resendConfirmationResult: "",
      open: false,
      message: ""
    };
  }
  setDisplay = componentToShow => {
    this.setState({ display: componentToShow });
  };
  handleClickOpen = () => {
    this.setState({ display: "RegistrationForm" });
  };
  handleClose = () => {
    this.setState({ display: "" });
  };
  handleTerms = () => {
    let payload = {
      ...this.state.payload,
      agreeTerms: !this.state.payload.agreeTerms
    };
    this.setState({ payload: payload });
    this.validate(payload);
  };
  handleMailingList = () => {
    let payload = {
      ...this.state.payload,
      mailingList: !this.state.payload.mailingList
    };
    this.setState({
      payload: payload
    });
    this.validate(payload);
  };

  handleChange = event => {
    let payload = {
      ...this.state.payload,
      [event.target.id]: event.target.value
    };
    this.setState({
      payload: payload
    });
    this.validate(payload);
  };
  validate(payload) {
    const regEmailRegex = /\S+@\S+\.\S+/;
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    let message, formIsValid;
    const { studentName, regEmail, password, confirmPassword } = payload;
    if (studentName === "") {
      formIsValid = false;
      message = "";
    } else if (
      !(studentName && studentName.length >= 3 && studentName.length <= 32)
    ) {
      formIsValid = false;
      message = "Name must be 3 to 32 characters.";
    } else if (regEmail === "") {
      formIsValid = false;
      message = "";
    } else if (!(regEmail && regEmailRegex.test(regEmail))) {
      formIsValid = false;
      message = "email is incorrect";
    } else if (password === "") {
      formIsValid = false;
      message = "";
    } else if (!(password && passwordRegex.test(password))) {
      formIsValid = false;
      message =
        "Password must contain uppercase letter, lowercase " +
        "letter, and number, at least 8 characters.";
    } else if (confirmPassword === "") {
      formIsValid = false;
      message = "";
    } else if (!(confirmPassword && confirmPassword === payload.password)) {
      formIsValid = false;
      message = "Please check that you have entered exactly the " +
        "same password in each field.";
    } else if (!payload.agreeTerms) {
      formIsValid = false;
      message = "Please accept our terms and conditions before registering."
    } else if (payload.agreeTerms) {
      formIsValid = true;
      message = "";
    }
    this.setState({
      formIsValid: formIsValid,
      message: message
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <Button
          round
          onClick={this.handleClickOpen}
          color="rose"
          size="sm"
          id="user_register_button"
        >
          Register
        </Button>
        <Dialog
          open={this.state.display !== ""}
          onClose={this.handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Student Registration</DialogTitle>
          <DialogContent>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  {this.state.display === "RegistrationForm" ? (
                    <RegistrationForm
                      classes={classes}
                      payload={this.state.payload}
                      formIsValid={this.state.formIsValid}
                      handleChange={this.handleChange}
                      handleClose={this.handleClose}
                      setDisplay={this.setDisplay}
                      handleTerms={this.handleTerms}
                      handleMailingList={this.handleMailingList}
                      message={this.state.message}
                    />
                  ) : null}
                  {this.state.display === "ConfirmForm" ? (
                    <ConfirmForm
                      classes={this.props.classes}
                      email={this.state.payload.regEmail}
                      password={this.state.payload.password}
                      mailingList={this.state.payload.mailingList}
                      display={this.state.display}
                      displayNewComponent={this.setDisplay}
                      checkAuthState={this.props.checkAuthState}
                    />
                  ) : null}
                </GridItem>
              </GridContainer>
            </div>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

SignUpStudent.propTypes = {
  classes: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired,
  history: PropTypes.object,
  checkAuthState: PropTypes.func.isRequired
};

export default withStyles(styles)(withCookies(SignUpStudent));
