import React, { Fragment } from "react";
import { instanceOf, PropTypes } from "prop-types";
import { Auth } from "aws-amplify";
import { Cookies, withCookies } from "react-cookie";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import CustomInput from "../CustomInput/CustomInput.jsx";
import Button from "../CustomButtons/Button.jsx";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardFooter from "../Card/CardFooter.jsx";

import loginPageStyle from "../../assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";
import { CircularProgress } from "@material-ui/core";

class ForgotPasswordLongForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimation: "cardHidden",
      forgotPasswordErrorMessage: "",
      isLoading: false,
    };
  }
  validateForgotPasswordForm() {
    return (
      (this.props.email.length > 0 && !this.state.isLoading)
    );
  }
  handleForgotPassword = async event => {
    event.preventDefault();
    this.setState({isLoading: true});
    await Auth.forgotPassword(this.props.email)
      .then(() => {
        this.props.displayNewComponent("ForgotPasswordLongForm");
      })
      .catch(err => {
        this.setState({
          forgotPasswordErrorMessage: err.message
        });
      });
    this.setState({isLoading: false})
  };
  render() {
    const { classes } = this.props;
    const {
      forgotPasswordErrorMessage,
    } = this.state;
    return (
      <Fragment>
        <form onSubmit={this.handleForgotPassword}>
          <Card className={classes[this.state.cardAnimation]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="rose"
            >
              <h4 className={classes.cardTitle}>Forgot Password</h4>
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Email..."
                id="forgotPasswordEmail"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email className={classes.inputAdornmentIcon}/>
                    </InputAdornment>
                  ),
                  onChange: this.props.setEmail,
                  value: this.props.email
                }}
              />
              <p>{forgotPasswordErrorMessage}</p>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button
                color="rose"
                simple
                size="lg"
                block
                disabled={!this.validateForgotPasswordForm()}
                type="submit"
              >
                {this.state.isLoading ? (
                  <CircularProgress />
                  ) : (
                    "Send Code to My Email"
                )}
              </Button>
            </CardFooter>
          </Card>
        </form>
      </Fragment>
    )
  }
}

ForgotPasswordLongForm.propTypes = {
  classes: PropTypes.object.isRequired,
  checkAuthState: PropTypes.func,
  cookies: instanceOf(Cookies).isRequired,
  history: PropTypes.object
};

export default withStyles(loginPageStyle)(withCookies(ForgotPasswordLongForm));
