import React, { Fragment } from "react";
import { instanceOf, PropTypes } from "prop-types";
import { Auth } from "aws-amplify";
import { Cookies, withCookies } from "react-cookie";
import { createStudent } from "../../libs/ilingu-lib/student";

import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Check from "@material-ui/icons/Check";
import Person from "@material-ui/icons/Person";
import PersonAdd from "@material-ui/icons/PersonAdd";
import EnhancedEncryption from "@material-ui/icons/EnhancedEncryption";
import Mail from "@material-ui/icons/Mail";
import Lock from "@material-ui/icons/Lock";
import Button from "../CustomButtons/Button.jsx";
import CustomInput from "../CustomInput/CustomInput.jsx";

import signupPageStyle from "../../assets/jss/ilingu/views/signupPageStyle";
import { CircularProgress } from "@material-ui/core";

const styles = {
  ...signupPageStyle,
  errorMsg: {
    color: "#f44336"
  },
  resendCodeLink: {
    cursor: "pointer",
    fontSize: "12px"
  },
  resendResult: {
    fontSize: "12px",
    color: "red"
  }
};

const tAndCStyle = {
  paddingLeft: "0.4rem"
};

class RegistrationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      display: "",
      open: false,
      isLoadingSignUp: false
    };
  }
  signIn = async () => {
    this.flagLoadingSignUp(true);
    try {
      await Auth.signIn(
        this.props.payload.regEmail,
        this.props.payload.password)
    } catch (e) {
      if (e.code === "UserNotConfirmedException") {
        this.flagLoadingSignUp(false);
        this.props.setDisplay("ConfirmForm")
      } else {
        this.setState({ loginErrorMessage: e.message });
        this.flagLoadingSignUp(false)
      }
    }
  };
  flagLoadingSignUp = (flag) => {
    this.setState({isLoadingSignUp: flag})
  };
  handleSignUp = async () => {
    this.flagLoadingSignUp(true);
    try {
      const newUser = await Auth.signUp({
        username: this.props.payload.regEmail,
        password: this.props.payload.password
      });
      let studentProfile = {
        userId: newUser.userSub,
        studentName: this.props.payload.studentName,
        email: this.props.payload.regEmail
      };
      if (this.props.payload.chineseName) {
        studentProfile.chineseName = this.props.payload.chineseName;
      }
      let teacherProfile = {
        ...studentProfile
      };
      if (teacherProfile.chineseName) {
        teacherProfile.teacherName = teacherProfile.chineseName;
        delete teacherProfile.chineseName;
      } else {
        teacherProfile.teacherName = teacherProfile.studentName;
      }
      // createTeacher(teacherProfile);
      await createStudent(studentProfile).then(async () => {
          await this.signIn()
        });
    } catch (e) {
      if (e.code !== "UserNotConfirmedException") {
        this.setState({
          authErrorMessage: e.message
        });
        this.flagLoadingSignUp(false);
      }
    }
  };
  render() {
    const { classes } = this.props;
    const fields = [
      {
        key: "1",
        id: "studentName",
        type: "text",
        icon: Person,
        placeholder: "Full Name..."
      },
      {
        key: "2",
        id: "chineseName",
        type: "text",
        icon: PersonAdd,
        placeholder: "Chinese Name(optional)..."
      },
      {
        key: "3",
        id: "regEmail",
        type: "regEmail",
        icon: Mail,
        placeholder: "Email..."
      },
      {
        key: "4",
        id: "password",
        type: "password",
        icon: Lock,
        placeholder: "Password..."
      },
      {
        key: "5",
        id: "confirmPassword",
        type: "password",
        icon: EnhancedEncryption,
        placeholder: "Confirm Password..."
      }
    ];
    return (
      <Fragment>
        <form className={classes.form}>
          {fields.map(prop => {
            return (
              <CustomInput
                key={prop.key}
                id={prop.id}
                formControlProps={{
                  fullWidth: true,
                  className: classes.customFormControlClasses
                }}
                inputProps={{
                  startAdornment: (
                    <InputAdornment
                      position="start"
                      className={classes.inputAdornment}
                    >
                      <prop.icon className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  placeholder: prop.placeholder,
                  onChange: this.props.handleChange,
                  type: prop.type,
                  value: this.props.payload[prop.id]
                }}
              />
            );
          })}
          <FormControlLabel
            classes={{
              root: classes.checkboxLabelControl,
              label: classes.checkboxLabel
            }}
            control={
              <Checkbox
                id={"mailing_list_checkbox"}
                data-cy={"mailchimp_subscribe_checkbox"}
                checked={this.props.payload.mailingList}
                value="mailing_list"
                onClick={() => this.props.handleMailingList()}
                checkedIcon={
                  <Check className={classes.checkedIcon} />
                }
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked
                }}
              />
            }
            label={
              <span>
                I'm happy to receive the iLingu newsletter with information
                about free Chinese learning resources.
              </span>
            }
          />
          <FormControlLabel
            classes={{
              root: classes.checkboxLabelControl,
              label: classes.checkboxLabel
            }}
            control={
              <Checkbox
                id={"TAndCRadio"}
                checked={this.props.payload.agreeTerms}
                value="Agree Terms"
                onClick={() => this.props.handleTerms()}
                checkedIcon={
                  <Check className={classes.checkedIcon} />
                }
                icon={<Check className={classes.uncheckedIcon} />}
                classes={{
                  checked: classes.checked
                }}
              />
            }
            label={
              <span>
                I agree to the
                <a
                  style={tAndCStyle}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://ilingu.com/terms"
                >
                  iLingu terms and conditions
                </a>.
              </span>
            }
          />
          <div className={classes.center}>
            <p className={classes.errorMsg}>
              {this.props.message}
            </p>
            {this.state.authErrorMessage ? (
              <p className={classes.errorMsg}>
                {this.state.authErrorMessage}
              </p>
            ) : null}
          </div>
          {this.state.isLoadingSignUp ? (
            <CircularProgress />
          ) : (
            <Fragment >
              <Button simple onClick={() => this.props.setDisplay("")} round color="rose">
                Cancel
              </Button>
              <Button
                id={"login_submit_button"}
                onClick={this.handleSignUp}
                round
                color="primary"
                disabled={!this.props.formIsValid}
              >
                Get started
              </Button>
            </Fragment>
          )}
        </form>
      </Fragment>
    );
  }
}

RegistrationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired,
  history: PropTypes.object,
  handleClose: PropTypes.func
};

export default withStyles(styles)(withCookies(RegistrationForm));
