import React from "react";
import PropTypes from "prop-types";
import { Switch } from "react-router-dom";

import AppliedRoute from "./AppliedRoute.js";
import AppRoutes from "../../routes/AppIndex";
import studentRoutes from "../../routes/AuthedRoutes";

const Routes = ({ childProps }) => {
  const { isAuthenticated } = childProps;
  let routes;
  if (isAuthenticated) {
    routes = [...studentRoutes, ...AppRoutes];
  } else {
    routes = AppRoutes;
  }
  return (
      <Switch>
        {routes.map((prop, key) => {
          return (
            <AppliedRoute
              path={prop.path}
              component={prop.component}
              key={key}
              props={childProps}
            />
          );
        })}
      </Switch>
  );
};

Routes.propTypes = {
  childProps: PropTypes.object.isRequired
};

export default Routes;
