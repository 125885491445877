// core libraries
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Analytics, Auth } from "aws-amplify";
import { StripeProvider } from "react-stripe-elements";
import { connect } from "react-redux";
import * as Sentry from "@sentry/browser";
// core components
import AppRoutes from "./components/Routes/AppRoutes";
import { brand } from "./views/globals";
import HeaderLinks from "./components/Header/HeaderLinks";
import Header from "./components/Header/Header";
import CustomFooter from "./components/Footer/CustomFooter";
// actions
import { fetchAddresses, fetchStudent, getIpStackData, setDisplayCurrency } from "./redux/actions/userInfo";
import {
  fetchProducts,
  fetchTeachers,
  listCoursesByStudentId,
  listEnrolmentsByStudentId,
  listLessonsByStudentId
} from "./redux/actions/courseInfo";
import {
  fetchBillingProfile,
  fetchPurchases,
  fetchSources,
  // listExchangeRates
} from "./redux/actions/financeInfo";
import { clearErrorMessage } from "./redux/actions/messageInfo";

import config from "./config";

Sentry.init(config.sentry);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      userRole: "",
      isAuthenticating: true,
      isLoadingData: true,
      stripe: null,
      // displayOlark: false
    };
  }
  componentDidMount() {
    if (window.Stripe) {
      this.setState({ stripe: window.Stripe(config.stripeKey) });
    } else {
      document.querySelector("#stripe-js").addEventListener("load", () => {
        // Create Stripe instance once Stripe.js loads
        this.setState({ stripe: window.Stripe(config.stripeKey) });
      });
    }
    // if (!this.state.isAuthenticated) {
    //   this.setState({displayOlark: true})
    // }
    // this.props.listExchangeRates();
    this.props.getIpStackData();
    this.checkAuthState();
    this.setState({ isLoadingData: false });
  }
  userHasRole = role => {
    this.setState({ userRole: role });
  };
  checkAuthState = async () => {
    await Auth.currentSession()
      .then(async userSession => {
        this.userHasAuthenticated(true);
        // this.toggleOlark();
        const groups = userSession.idToken.payload["cognito:groups"];
        if (groups !== undefined && groups.length !== 0) {
          if (groups.includes("Student")) {
            this.userHasRole("Student");
            this.setState({ isLoadingData: true });
            if (this.state.isAuthenticated) {
              await this.props.fetchStudent().then(data => {
                // console.log("fetchStudentData returned", data);
                let userId = data.student.userId;
                Analytics.updateEndpoint({
                  userId: data.student.userId,
                  location: {
                    city: this.props.userInfo.ipStackData.city, // The city where the endpoint is located.
                    country: this.props.userInfo.ipStackData.country_code, // The two-letter code for the country or region of the endpoint. Specified as an ISO 3166-1 alpha-2 code, such as "US" for the United States.
                    latitude: this.props.userInfo.ipStackData.latitude, // The latitude of the endpoint location, rounded to one decimal place.
                    longitude: this.props.userInfo.ipStackData.longitude, // The longitude of the endpoint location, rounded to one decimal place.
                    postalCode: this.props.userInfo.ipStackData.zip, // The postal code or zip code of the endpoint.
                    region: this.props.userInfo.ipStackData.region_name // The region of the endpoint location. For example, in the United States, this corresponds to a state.
                  }
                });
                Sentry.configureScope(scope => {
                  scope.setUser({
                    id: userId,
                    email: data.student.email,
                    username: data.student.studentName
                  });
                });
                this.props.fetchTeachers();
                this.props.listCoursesByStudentId(userId);
                this.props.listEnrolmentsByStudentId(userId);
                this.props.listLessonsByStudentId(userId);
                this.props.fetchAddresses(userId);
                this.props.fetchProducts();
                this.props.fetchPurchases(userId);
                this.props.fetchBillingProfile(userId).then(res => {
                  this.props.fetchSources(
                    res.billingProfile.paymentMethods.stripe.customerId
                  );
                });
              });
            }
          }
        } else {
          alert("Please ask system manager set your Student role.");
          this.userHasAuthenticated(false);
        }
      })
      .catch(err => console.log(err));
    this.setState({ isLoadingData: false });
    this.setState({ isAuthenticating: false });
  };
  userHasAuthenticated = authenticated => {
    this.setState({ isAuthenticated: authenticated });
  };
  handleLogout = async () => {
    await Auth.signOut();
    this.userHasAuthenticated(false);
    this.props.history.push("/");
  };
  // toggleOlark() {
  //   this.setState({
  //     displayOlark: !this.state.displayOlark
  //   })
  // }
  render() {
    // const { msg } = this.props.messageInfo;
    // if(this.state.displayOlark) {
    //   window.olark('api.box.show')
    // } else {
    //   window.olark('api.box.hide')
    // }
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      isAuthenticating: this.state.isAuthenticating,
      isLoadingData: this.state.isLoadingData,
      userRole: this.state.userRole,
      checkAuthState: this.checkAuthState,
      handleLogout: this.handleLogout
    };
    const { isAuthenticating } = this.state;
    return (
      <Fragment>
        {isAuthenticating ? null : (
          <Fragment>
            {/*<div>*/}
            {/*  <p>words{JSON.stringify(this.state.displayOlark)}</p>*/}
            {/*</div>*/}
            <Header
              color="info"
              childProps={childProps}
              routes={[]}
              brand={brand}
              links={
                <HeaderLinks
                  dropdownHoverColor="info"
                  history={this.props.hist}
                  checkAuthState={this.checkAuthState}
                  isAuthenticated={this.state.isAuthenticated}
                  userRole={this.state.userRole}
                  // toggleOlark={() => this.toggleOlark()}
                />
              }
              fixed
            />
            <StripeProvider
              stripe={this.state.stripe}
              betas={["payment_intent_beta_3"]}
            >
              <AppRoutes childProps={childProps} />
            </StripeProvider>
            <CustomFooter history={this.props.history} />
          </Fragment>
        )}
      </Fragment>
    );
  }
}

App.propTypes = {
  history: PropTypes.object,
  messageInfo: PropTypes.object,
  fetchStudent: PropTypes.func,
  fetchAvatarURL: PropTypes.func,
  clearErrorMessage: PropTypes.func,
  fetchBillingProfile: PropTypes.func,
  fetchSources: PropTypes.func,
  fetchAddresses: PropTypes.func,
  fetchCourses: PropTypes.func,
  fetchProducts: PropTypes.func,
  fetchTeachers: PropTypes.func,
  // listExchangeRates: PropTypes.func.isRequired,
  getIpStackData: PropTypes.func.isRequired,
  userInfo: PropTypes.object.isRequired,
  listCoursesByStudentId: PropTypes.func.isRequired,
  listEnrolmentsByStudentId: PropTypes.func.isRequired,
  listLessonsByStudentId: PropTypes.func.isRequired,
  fetchPurchases: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    messageInfo: state.messageInfo,
    userInfo: state.userInfo,
    courseInfo: state.courseInfo,
    financeInfo: state.financeInfo
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    {
      fetchStudent,
      clearErrorMessage,
      fetchBillingProfile,
      fetchPurchases,
      fetchSources,
      fetchAddresses,
      fetchProducts,
      fetchTeachers,
      listEnrolmentsByStudentId,
      listLessonsByStudentId,
      listCoursesByStudentId,
      // listExchangeRates,
      getIpStackData,
      setDisplayCurrency
    }
  )(App)
);
