import asyncComponent from "../components/Routes/AsyncComponent.js"

const AsyncStudentProfile = asyncComponent(() => import("../views/AuthedViews/User/StudentProfile"));
const AsyncMyCourses = asyncComponent(() => import("../views/AuthedViews/MyCourses"));
const AsyncBillingDetails = asyncComponent(() => import("../views/AuthedViews/Billing"));
// const AsyncBookingChooseTeacher = asyncComponent(() => import("../views/AuthedViews/MyCourses/Booking/ChooseTeacher"));
const AsyncLessonCalendar = asyncComponent(() => import("../views/AuthedViews/User/Lessons/Calendar"));

const studentRoutes = [
  { path: "/student/profile", name: "Profile", component: AsyncStudentProfile },
  { path: "/student/billing", name: "billing", component: AsyncBillingDetails },
  { path: "/student/courses", name: "Profile", component: AsyncMyCourses },
  // { path: "/student/booking/choose-teacher", name: "BookingChooseTeacher", component: AsyncBookingChooseTeacher },
  { path: "/student/lessons/calendar", name: "LessonCalendar", component: AsyncLessonCalendar },
];

export default studentRoutes;
