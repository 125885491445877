const rp = require("request-promise");

export async function getIpStackData() {
  const options = {
    uri: 'https://api.ipstack.com/check',
    qs: {
      access_key: 'fa0559a71c937ae4d6e0a179e23c6e7e' // -> uri + '?access_token=xxxxx%20xxxxx'
    },
    json: true // Automatically parses the JSON string in the response
  };
  return rp(options)
}