import { API } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import config from "../../../config";

Sentry.init(config.sentry);

export async function updateNotificationPreferencesAPI(courseId, notificationPreferences) {
  try {
    return await API.put("ilingu", `/courses/${courseId}`, {
      body: notificationPreferences
    })
  } catch (e) {
    Sentry.withScope(scope => {
      scope.setTag("file", "src/libs/ilingu-lib/courses/updateNotificationPreferences.js:15");
      scope.setExtra("data", {
        courseId: courseId,
        notificationPreferences: notificationPreferences
      });
      Sentry.captureException(e);
    })
  }
}