import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridContainer from "../Grid/GridContainer.jsx";
import GridItem from "../Grid/GridItem.jsx";
import Button from "../CustomButtons/Button.jsx";
import Footer from "../Footer/Footer.jsx";
import MailchimpSignup from "../Footer/MailchimpSignup";
import CurrencySwitcher from "../CurrencySwitcher/CurrencySwitcher";

import styles from "../../assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.jsx";

const SectionFooter = props => {
  const { classes } = props;
  return (
    <div className={classes.section}>
      <div>
        <Footer
          theme="white"
          content={
            <div>
              <ul className={classes.socialButtons}>
                <li>
                  <Button justIcon simple color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                </li>
                <li>
                  <Button justIcon simple color="facebook">
                    <i className="fab fa-facebook-square" />
                  </Button>
                </li>
                <li>
                  <Button justIcon simple color="dribbble">
                    <i className="fab fa-dribbble" />
                  </Button>
                </li>
                <li>
                  <Button justIcon simple color="google">
                    <i className="fab fa-google-plus-g" />
                  </Button>
                </li>
                <li>
                  <Button justIcon simple color="youtube">
                    <i className="fab fa-youtube" />
                  </Button>
                </li>
              </ul>
              <div
                className={classNames(classes.pullCenter, classes.copyRight)}
              >
                Copyright &copy; {1900 + new Date().getYear()}{" "}
                ILINGU LTD, All
                Rights Reserved.
              </div>
            </div>
          }
        >
          <div className={classes.footer}>
            <GridContainer>
              <GridItem xs={12} sm={3} md={3}>
                <a href="#ilingu" onClick={() => props.history.push("/")}>
                  <h5>iLingu Languages</h5>
                </a>
                <p>
                  More than just vocabulary building.
                  We train foreign language communication skills for long-term success.
                </p>
                <CurrencySwitcher longVersion />
              </GridItem>
              <GridItem xs={12} sm={2} md={2}>
                <h5>About</h5>
                <ul className={classes.linksVertical}>
                  {/*<li>*/}
                    {/*<a href="#pablo">Blog</a>*/}
                  {/*</li>*/}
                  <li>
                    <a onClick={() => props.history.push("/our-school")} href="#ilingu">About us</a>
                  </li>
                  <li>
                    <a onClick={() => props.history.push("/contact")} href="#ilingu">Contact us</a>
                  </li>
                </ul>
              </GridItem>
              {/*<GridItem xs={12} sm={2} md={2}>*/}
                {/*<h5>Admissions</h5>*/}
                {/*<ul className={classes.linksVertical}>*/}
                  {/*<li>*/}
                    {/*<a href="#">How to Apply</a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                    {/*<a href="#">Course Comparisons</a>*/}
                  {/*</li>*/}
                  {/*<li>*/}
                    {/*<a href="#">Tuition Fees</a>*/}
                  {/*</li>*/}
                {/*</ul>*/}
              {/*</GridItem>*/}
              <GridItem xs={12} sm={2} md={2}>
                <h5>Legal</h5>
                <ul className={classes.linksVertical}>
                  <li>
                    <a onClick={() => props.history.push("/terms")} href="#">Terms & conditions</a>
                  </li>
                  {/*<li>*/}
                    {/*<a href="#">Licenses</a>*/}
                  {/*</li>*/}
                </ul>
              </GridItem>
              <MailchimpSignup/>
            </GridContainer>
            <div className={classes.textCenter}>

            </div>
          </div>
        </Footer>
      </div>
    </div>
  );
};

export default withStyles(styles)(SectionFooter);
