// core libraries
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import { CookiesProvider } from "react-cookie";
import * as Sentry from "@sentry/browser";
import * as ReactGA from "react-ga";
import Amplify from "aws-amplify";
// app
import App from "./App.js";
// store
import store from "./redux/store";
// config
import config from "./config";
// styles
import "./assets/scss/material-kit-pro-react.css?v=1.1.0";

Sentry.init(config.sentry);

let hist = createBrowserHistory();

// if (process.env.REACT_APP_STAGE === ('production' || 'staging')) {
// if (process.env.REACT_APP_STAGE === ('production')) {
hist.listen(() => {
  // ReactGA.pageview(window.location.pathname + window.location.search);
  window.gtag("event", "page_view", {
    page_path: window.location.pathname + window.location.search,
    send_to: "UA-71956597-1"
  });
});
// }

Amplify.configure({
  Auth: config.amplify.Auth,
  Storage: config.amplify.Storage,
  API: {
    endpoints: [
      ...config.amplify.API.endpoints,
    ]
  },
  Analytics: config.amplify.Analytics,
});

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={hist}>
      <CookiesProvider>
        <App hist={hist} />
      </CookiesProvider>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);

if (window.Cypress) {
  window.store = store
}

// if (process.env.REACT_APP_STAGE === ('production' || 'staging')) {
if (process.env.REACT_APP_STAGE === ('production')) {
  ReactGA.pageview(window.location.pathname + window.location.search);
}
