import { API } from "aws-amplify";

export async function getTeacherByTeacherId(teacherId) {
  return await API.get("ilingu", `/teachers/${teacherId}`, {});
}

export async function createTeacher(teacher) {
  return await API.post("ilingu", "/teachers", {
    body: teacher
  });
}

export async function listTeachers(startToken) {
  try {
    let params = {
      queryStringParameters: {
        approved: true
      }
    };
    if (startToken !== null && startToken !== undefined) {
      params.queryStringParameters = {
        ...params.queryStringParameters,
        startToken: startToken
      };
    }
    return await API.get("ilingu", "/teachers", params).then(result => {
      // console.log("result: ", result);
      return result.Items;
    });
  } catch (e) {
    console.log('listTeachers failed with error: ', e)
  }
}
