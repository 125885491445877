import {
  ATTACHED_SOURCE,
  ATTACHING_SOURCE,
  CHANGED_DEFAULT_SOURCE,
  CHANGING_DEFAULT_SOURCE,
  DELETED_SOURCE,
  DELETING_SOURCE,
  LIST_EXCHANGE_RATES_RETURN,
  LIST_EXCHANGE_RATES_START,
  RECEIVE_BILLING_PROFILE,
  RECEIVE_SOURCES,
  REQUEST_BILLING_PROFILE,
  REQUEST_PURCHASES_RETURN,
  REQUEST_PURCHASES_START,
  REQUEST_SOURCES,
  UPDATE_BILLING_PROFILE_RETURN,
  UPDATE_BILLING_PROFILE_START
} from "../action-types";

import { getErrorMessage } from "./messageInfo";

import {
  attachSource as attachSourceAPI,
  changeDefaultSource as changeDefaultSourceAPI,
  deleteSource as deleteSourceAPI,
  getBillingProfile,
  getSources,
  listExchangeRates as listExchangeRatesAPI,
  listPurchasesByUserId,
  updateBillingProfile as updateBillingProfileAPI
} from "../../libs/ilingu-lib/finance";

import * as Sentry from "@sentry/browser";
import config from "../../config";

Sentry.init(config.sentry);

export const listExchangeRatesStart = () => ({
  type: LIST_EXCHANGE_RATES_START,
  isLoadingExchangeRates: true,
});
export const listExchangeRatesReturn = (response) => ({
  type: LIST_EXCHANGE_RATES_RETURN,
  isLoadingExchangeRates: false,
  exchangeRates: response.exchangeRates,
  currencies: response.currencies,
});
export function listExchangeRates() {
  return function(dispatch) {
    dispatch(listExchangeRatesStart());

    return listExchangeRatesAPI().then(
      response => dispatch(listExchangeRatesReturn(response)),
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    )
  }
}

export const requestPurchasesStart = () => ({
  type: REQUEST_PURCHASES_START,
  isLoadingPurchases: true,
});
export const requestPurchasesReturn = (purchases) => ({
  type: REQUEST_PURCHASES_RETURN,
  isLoadingPurchases: false,
  purchases: purchases,
});
export function fetchPurchases(userId) {
  return function(dispatch) {
    dispatch(requestPurchasesStart());

    return listPurchasesByUserId(userId).then(
      response => dispatch(requestPurchasesReturn(response)),
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    )
  }
}

export const requestBillingProfile = () => ({
  type: REQUEST_BILLING_PROFILE,
  isLoadingBillingProfile: true
});

export const receiveBillingProfile = billingProfile => ({
  type: RECEIVE_BILLING_PROFILE,
  billingProfile: billingProfile,
  isLoadingBillingProfile: false
});

export function fetchBillingProfile(userId) {
  return function(dispatch) {
    dispatch(requestBillingProfile());

    return getBillingProfile(userId).then(
      response => dispatch(receiveBillingProfile(response)),
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}

export const updateBillingProfileStart = () => ({
  type: UPDATE_BILLING_PROFILE_START,
  isLoadingBillingProfile: true,
});
export const updateBillingProfileReturn = (response) => ({
  type: UPDATE_BILLING_PROFILE_RETURN,
  isLoadingBillingProfile: false,
  billingProfile: response,
});
export function updateBillingProfile(userId, updateData) {
  return function(dispatch) {
    dispatch(updateBillingProfileStart());

    return updateBillingProfileAPI(userId, updateData).then(
      response => {dispatch(updateBillingProfileReturn(response))},
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "UPDATE BILLING PROFILE API ERROR"))
      }
    )
  }
}

export const requestSources = () => ({
  type: REQUEST_SOURCES,
  isLoadingSources: true
});

export const receiveSources = sources => ({
  type: RECEIVE_SOURCES,
  sources: sources,
  isLoadingSources: false
});

export function fetchSources(stripeCustomerId) {
  return function(dispatch) {
    dispatch(requestSources());

    return getSources(stripeCustomerId).then(
      response => dispatch(receiveSources(response)),
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}

export const attachingSource = () => ({
  type: ATTACHING_SOURCE,
  isAttachingSource: true
});

export const attachedSource = () => ({
  type: ATTACHED_SOURCE,
  isAttachingSource: false
});

export function attachSource(stripeCustomerId, sourceId) {
  return function(dispatch) {
    dispatch(attachingSource());

    return attachSourceAPI(stripeCustomerId, sourceId).then(
      () => {
        dispatch(attachedSource());
        dispatch(fetchSources(stripeCustomerId));
      },
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}

export const deletingSource = (sourceId) => ({
  type: DELETING_SOURCE,
  deletingSourceId: sourceId
});

export const deletedSource = () => ({
  type: DELETED_SOURCE
});

export function deleteSource(stripeCustomerId, sourceId) {
  return function(dispatch) {
    dispatch(deletingSource(sourceId));

    return deleteSourceAPI(stripeCustomerId, sourceId).then(
      () => {
        dispatch(deletedSource());
        dispatch(fetchSources(stripeCustomerId));
      },
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}

export const changingDefaultSource = (sourceId) => ({
  type: CHANGING_DEFAULT_SOURCE,
  changingToDefaultSourceId: sourceId
});

export const changedDefaultSource = () => ({
  type: CHANGED_DEFAULT_SOURCE
});

export function changeDefaultSource(stripeCustomerId, sourceId) {
  return function(dispatch) {
    dispatch(changingDefaultSource(sourceId));

    return changeDefaultSourceAPI(stripeCustomerId, sourceId).then(
      () => {
        dispatch(changedDefaultSource());
        dispatch(fetchSources(stripeCustomerId));
      },
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}
