import { API } from "aws-amplify";

// export async function listAddressesByUserId(userId) {
//   try {
//     return await API.get("ilingu", `/addresses/${userId}`, {});
//   } catch (e) {
//     console.log(e);
//     return e
//   }
// }

export async function listAddresses(userId) {
  try {
    return await API.get("ilingu", `/addresses/${userId}`, {});
  } catch (e) {
    console.log(e);
    return e
  }
}

export async function createAddress(userId, address) {
  let addressBody = {
    userId: userId,
    country: address.country,
    postCode: address.postCode,
    state: address.state,
    city: address.city,
    addressLine1: address.addressLine1
  };
  if (address.addressLine2) {
    addressBody.addressLine2 = address.addressLine2;
  }
  if (address.addressLine3) {
    addressBody.addressLine3 = address.addressLine3;
  }
  return API.post("ilingu", "/addresses", {
    body: addressBody
  });
}

export async function changeDefaultAddress(userId, addressId) {
  return await API.put("ilingu", `/addresses/active/${userId}`, {
    body: { addressId: addressId }
  });
}