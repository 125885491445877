import React, { Fragment } from "react";
import { Auth } from "aws-amplify";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
// @material-ui/icons
import Close from "@material-ui/icons/Close";
// core components
import Button from "../CustomButtons/Button.jsx";

import javascriptStyles from "../../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.jsx";
import Info from "../Typography/Info.jsx";

const signOutStyle = {
  fontSize: '15px',
  textAlign: 'center',
  display: 'flex',
  paddingTop: '0.75rem',
  paddingRight: '1.25rem',
  paddingBottom: '0.75rem',
  paddingLeft: '0.75rem',};

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class SectionJavascript extends React.Component {
  anchorElLeft = null;
  anchorElTop = null;
  anchorElBottom = null;
  anchorElRight = null;
  constructor(props) {
    super(props);
    this.state = {
      smallModal: false,
      openLeft: false,
      openTop: false,
      openBottom: false,
      openRight: false,
      checked: []
    };
    this.handleToggle = this.handleToggle.bind(this);
  }
  handleToggle(value) {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked
    });
  }
  handleClickOpen(modal) {
    const x = [];
    x[modal] = true;
    this.setState(x);
  }
  handleClose(modal) {
    const x = [];
    x[modal] = false;
    this.setState(x);
  }
  handleConfirm = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
      window.location.href = "/";
    } catch(e) {
      console.log(e)
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <Fragment>
        <span
          onClick={() => this.handleClickOpen("smallModal")}
          id="sign_out_span"
          style={signOutStyle}
        >
          <Info>Sign Out</Info>
        </span>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal + " " + classes.modalSmall
          }}
          open={this.state.smallModal}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => this.handleClose("noticeModal")}
          aria-labelledby="small-modal-slide-title"
          aria-describedby="small-modal-slide-description"
        >
          <DialogTitle
            id="small-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <Button
              simple
              className={classes.modalCloseButton}
              key="close"
              aria-label="Close"
              onClick={() => this.handleClose("smallModal")}
            >
              {" "}
              <Close className={classes.modalClose} />
            </Button>
          </DialogTitle>
          <DialogContent
            id="small-modal-slide-description"
            className={
              classes.modalBody + " " + classes.modalSmallBody
            }
          >
            <h5>Are you sure you want to do sign out of iLingu.com?</h5>
          </DialogContent>
          <DialogActions
            className={
              classes.modalFooter + " " + classes.modalFooterCenter
            }
          >
            <Button
              onClick={() => this.handleClose("smallModal")}
              link
              className={classes.modalSmallFooterFirstButton}
            >
              Never Mind
            </Button>
            <Button
              id="sign_out_confirm_button"
              onClick={() => this.handleConfirm()}
              color="success"
              simple
              className={
                classes.modalSmallFooterFirstButton +
                " " +
                classes.modalSmallFooterSecondButton
              }
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

export default withStyles(javascriptStyles)(SectionJavascript);
