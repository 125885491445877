import { API } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import config from "../../config";

Sentry.init(config.sentry);

export async function getProduct(productId) {
  try {
  return await API.get("ilingu", `/products/${productId}`, {});
  } catch (e) {
    Sentry.captureException(e);
    if (process.env.REACT_APP_STAGE !== "production") {
    console.log(e);
    }
  }
}

export async function listProducts() {
  try {
    return await API.get("ilingu", "/products", {
    })
  } catch (e) {
    Sentry.captureException(e);
    if (process.env.REACT_APP_STAGE !== "production") {
      console.log(e);
    }
  }
}

