import React from "react";
import { API } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import config from "../../config";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
// core components
import GridItem from "../Grid/GridItem.jsx";
import CustomInput from "../CustomInput/CustomInput.jsx";
import Button from "../CustomButtons/Button.jsx";
// Material UI Icons
import Mail from "@material-ui/icons/Mail";
// Styles
import styles from "../../assets/jss/material-kit-pro-react/views/componentsSections/footerStyle.jsx";


Sentry.init(config.sentry);

class MailchimpSignup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      subscriber: {
        email: ""
      }
    };
  }

  validateForm() {
    let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return this.state.subscriber.email.length > 0 && re.test(this.state.subscriber.email)
  }

  handleChange = event => {
    const { subscriber } = this.state;
    this.setState({
      subscriber: {
        ...subscriber,
        [event.target.id] : event.target.value
      }
    })
  };

  handleSubmit = async event => {
    event.preventDefault();

    try {
      await this.createForm(this.state.subscriber);
      this.setState({
        dialogOpen: true,
        subscriber: {
          email: ""
        }
      })
    } catch (e) {
      console.log(e);
    }
  };

  async createForm(content) {
    await API.get("ilingu", "/mailchimp/subscribe", {
      queryStringParameters: {
        subscriber: content.email
      }
    })
      .then(() => alert("Thanks, you've been successfully."))
      .catch(err => {
        Sentry.captureException(err);
        alert('Sorry, something went wrong. Please check your email address.')
      })
  }

  render() {
    const { classes } = this.props;
    return (
      <GridItem xs={12} sm={3} md={3}>
        <h5>Subscribe to Newsletter</h5>
        <p>
          Join our newsletter and stay up to date with events, offers, and study tips!
        </p>
        <form onSubmit={this.handleSubmit}>
          <CustomInput
            labelText="Your Email"
            id="email"
            inputProps={{
              type: "email",
              placeholder: "Your Email",
              onChange: this.handleChange,
              value: this.state.subscriber.email
            }}
            formControlProps={{
              fullWidth: false
            }}
          />
          <Button
            color="primary"
            type="submit"
            className={classes.pullRight}
            disabled={!this.validateForm()}
            justIcon
          >
            <Mail />
          </Button>
        </form>
      </GridItem>
    );
  }
}

export default withStyles(styles)(MailchimpSignup);
