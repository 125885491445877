import React, { Fragment } from "react";
import { instanceOf, PropTypes } from "prop-types";
import { Auth } from "aws-amplify";
import { Cookies, withCookies } from "react-cookie";
import { CircularProgress } from "@material-ui/core";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Email from "@material-ui/icons/Email";
// core components
import CustomInput from "../CustomInput/CustomInput.jsx";
import Button from "../CustomButtons/Button.jsx";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardFooter from "../Card/CardFooter.jsx";
// styles
import buttonStyle from "../../assets/jss/material-kit-pro-react/components/buttonStyle.jsx";
import loginPageStyle from "../../assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";

const styles = {
  ...loginPageStyle,
  ...buttonStyle,
};

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      display: this.props.display,
      isSigningIn: false,
    };
  }

  validateLoginForm() {
    return (
      this.props.email.length > 0 &&
      this.props.password.length > 0
    );
  }
  handleChange = event => {
    this.setState({
        [event.target.id]: event.target.value
    });
  };
  handleSignIn = async event => {
    event.preventDefault();
    this.setState({
      isSigningIn: true,
    });
    try {
      await Auth.signIn(
        this.props.email.toLowerCase(),
        this.props.password).then(user => {
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            this.setState({
              loginErrorMessage: "Please change your original password."
            });
            //TODO Send verification code and then push to change password field
          } else {
            this.setState({
              isSigningIn: false,
            });
            this.props.checkAuthState();
            this.props.displayNewComponent("");
          }
        });
    } catch (e) {
      if (e.hasOwnProperty('code') && e.code === "UserNotConfirmedException") {
        this.props.displayNewComponent("ConfirmForm");
      } else {
        this.setState({
          loginErrorMessage: e.message
        });
      }
      this.setState({
        isSigningIn: false,
      })
    }
  };
  render() {
    const { classes } = this.props;
    const {
      loginErrorMessage,
    } = this.state;
    /**
     * If a user has never logged in before, they are the confirm attribute component
     *
     * If they forget their password they are shown the forgot password component
     *
     *
     */
    return (
      <Fragment>
        <form onSubmit={this.handleSignIn}>
          <Card className={classes[this.state.cardAnimation]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="rose"
            >
              <h4 className={classes.cardTitle}>Log in</h4>
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Email..."
                id="emailAsName"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  onChange: this.props.setEmail,
                  value: this.props.email
                }}
              />
              <CustomInput
                labelText="Password"
                id="password"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                      </Icon>
                    </InputAdornment>
                  ),
                  type: "password",
                  onChange: this.props.setPassword,
                  value: this.props.password
                }}
              />
              <p>
                <Button
                  simple
                  color="rose"
                  className={classes.forgotPasswordLink}
                  onClick={() => this.props.displayNewComponent("ForgotPasswordForm")}
                >
                  Forgot Password?
                </Button>
              </p>
              <p>{loginErrorMessage}</p>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button
                color="rose"
                simple
                size="lg"
                block
                disabled={!this.validateLoginForm() || this.state.isSigningIn}
                type="submit"
                id="login_submit_button"
              >
                {this.state.isSigningIn ? <CircularProgress id={"LoginFormLoadingIcon"} /> : "Login"}
              </Button>
            </CardFooter>
          </Card>
        </form>
      </Fragment>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  checkAuthState: PropTypes.func,
  cookies: instanceOf(Cookies).isRequired,
  history: PropTypes.object
};

export default withStyles(styles)(withCookies(Login));
