import {
  ADDED_TRIAL_QUESTIONNAIRE,
  ADDING_TRIAL_QUESTIONNAIRE,
  CANCEL_LESSON_RETURN,
  CANCEL_LESSON_START,
  CREATED_COURSE,
  CREATED_ENROLMENT,
  CREATED_LESSON,
  CREATING_COURSE,
  CREATING_ENROLMENT,
  CREATING_LESSON,
  LIST_COURSES_BY_STUDENT_ID_RETURN,
  LIST_COURSES_BY_STUDENT_ID_START,
  LIST_ENROLMENTS_BY_STUDENT_ID_RETURN,
  LIST_ENROLMENTS_BY_STUDENT_ID_START,
  LIST_LESSONS_BY_STUDENT_ID_RETURN,
  LIST_LESSONS_BY_STUDENT_ID_START,
  RECEIVE_COURSES,
  RECEIVE_ENROLMENTS,
  RECEIVE_PRODUCTS,
  RECEIVE_TEACHER_AVAILABILITIES,
  RECEIVE_TEACHER_LESSONS,
  RECEIVE_TEACHERS,
  REQUEST_COURSES,
  REQUEST_ENROLMENTS,
  REQUEST_PRODUCTS,
  REQUEST_TEACHER_AVAILABILITIES,
  REQUEST_TEACHER_LESSONS,
  REQUEST_TEACHERS,
  UPDATE_NOTIFICATION_PREFERENCES_RETURN,
  UPDATE_NOTIFICATION_PREFERENCES_START
} from "../action-types";

export default function getCourseInfo(
  state = {
    courses: [],
    coursesById: {},
    enrolments: [],
    enrolmentsById: {},
    lessons: [],
    lessonsById: {},
    products: [],
    productsById: {},
    teachers: [],
    teachersById: {},
    teacherLessons: {},
    isAddingTrialQuestionnaire: false,
    isAddedTrialQuestionnaire: false,
    isCreatingCourse: false,
    isCreatingEnrolment: false,
    isCreatingLesson: false,
    isLoadingCancelLesson: false,
    isLoadingCourses: false,
    isLoadingEnrolments: false,
    isLoadingProducts: false,
    isLoadingTeachers: false,
    isLoadingTeacherLessons: false,
    isLoadingTeacherAvailabilities: false
  },
  action
) {
  let enrolmentsById = {};
  let coursesById = {};
  switch (action.type) {
    case UPDATE_NOTIFICATION_PREFERENCES_START:
      return Object.assign({}, state, {
        isLoadingCourses: action.isLoadingCourses
      });
    case UPDATE_NOTIFICATION_PREFERENCES_RETURN:
      coursesById = {
        ...state.coursesById,
        [action.course.courseId]: action.course
      };
      let updatedCourses = state.courses.filter(
        course => course.courseId !== action.course.courseId
      );
      updatedCourses.push(action.course);
      return Object.assign({}, state, {
        isLoadingCourses: action.isLoadingCourses,
        coursesById: coursesById,
        courses: updatedCourses
      });
    case LIST_COURSES_BY_STUDENT_ID_START:
      return Object.assign({}, state, {
        isLoadingCourses: action.isLoadingCourses
      });
    case LIST_COURSES_BY_STUDENT_ID_RETURN:
      // console.log("action: ", action);
      // console.log("courses: ", action.courses);
      action.courses = action.courses.map(course => {
        course.enrolments.forEach(enrolment => {
          enrolmentsById = {
            ...enrolmentsById,
            [enrolment.enrolmentId]: enrolment
          };
        });
        return {
          ...course,
          enrolmentsById: enrolmentsById
        };
      });
      coursesById = {};
      action.courses.forEach(course => {
        coursesById = {
          ...coursesById,
          [course.courseId]: course
        };
      });
      return Object.assign({}, state, {
        isLoadingCourses: action.isLoadingCourses,
        courses: action.courses,
        coursesById: coursesById
      });
    case LIST_ENROLMENTS_BY_STUDENT_ID_START:
      return Object.assign({}, state, {
        isLoadingEnrolments: action.isLoadingEnrolments
      });
    case LIST_ENROLMENTS_BY_STUDENT_ID_RETURN:
      action.enrolments.Items.forEach(enrolment => {
        enrolmentsById = {
          ...enrolmentsById,
          [enrolment.enrolmentId]: enrolment
        };
      });
      return Object.assign({}, state, {
        isLoadingEnrolments: action.isLoadingEnrolments,
        enrolments: action.enrolments.Items,
        enrolmentsById: enrolmentsById
      });
    case LIST_LESSONS_BY_STUDENT_ID_START:
      return Object.assign({}, state, {
        isLoadingLessons: action.isLoadingLessons
      });
    case LIST_LESSONS_BY_STUDENT_ID_RETURN:
      let lessonsById = {};
      action.lessons.Items.forEach(lesson => {
        lessonsById = {
          ...lessonsById,
          [lesson.lessonId]: lesson
        };
      });
      return Object.assign({}, state, {
        isLoadingLessons: action.isLoadingLessons,
        lessons: action.lessons.Items,
        lessonsById: lessonsById
      });
    case CANCEL_LESSON_START:
      return Object.assign({}, state, {
        isLoadingCancelLesson: action.isLoadingCancelLesson
      });
    case CANCEL_LESSON_RETURN:
      return Object.assign({}, state, {
        isLoadingCancelLesson: action.isLoadingCancelLesson
      });
    case REQUEST_COURSES:
      return Object.assign({}, state, {
        isLoadingCourses: action.isLoadingCourses
      });
    case RECEIVE_COURSES:
      return Object.assign({}, state, {
        courses: action.courses,
        isLoadingCourses: action.isLoadingCourses
      });
    case REQUEST_ENROLMENTS:
      return Object.assign({}, state, {
        isLoadingEnrolments: action.isLoadingEnrolments
      });
    case RECEIVE_ENROLMENTS:
      return Object.assign({}, state, {
        enrolments: action.enrolments,
        enrolmentMappingByCourseId: action.enrolmentMappingByCourseId,
        isLoadingEnrolments: action.isLoadingEnrolments
      });
    case ADDING_TRIAL_QUESTIONNAIRE:
      return Object.assign({}, state, {
        isAddingTrialQuestionnaire: action.isAddingTrialQuestionnaire
      });
    case ADDED_TRIAL_QUESTIONNAIRE:
      return Object.assign({}, state, {
        isAddingTrialQuestionnaire: action.isAddingTrialQuestionnaire,
        isAddedTrialQuestionnaire: action.isAddedTrialQuestionnaire
      });
    case REQUEST_PRODUCTS:
      return Object.assign({}, state, {
        isLoadingProducts: action.isLoadingProducts
      });
    case RECEIVE_PRODUCTS:
      let products = action.products.Items.map(product => {
        let pricesMap = {};
        product.prices.forEach(price => {
          pricesMap = {
            ...pricesMap,
            [price.currency]: price.amount
          };
        });
        return {
          ...product,
          pricesMap: pricesMap
        };
      });
      let productsById = {};
      products.forEach(product => {
        productsById = {
          ...productsById,
          [product.productId]: product
        };
      });
      return Object.assign({}, state, {
        products: products,
        productsById: productsById,
        isLoadingProducts: action.isLoadingProducts
      });
    case REQUEST_TEACHERS:
      return Object.assign({}, state, {
        isLoadingTeachers: action.isLoadingTeachers
      });
    case RECEIVE_TEACHERS:
      return Object.assign({}, state, {
        teachers: action.teachers,
        teachersById: action.teacherMappingByUserId,
        isLoadingTeachers: action.isLoadingTeachers
      });
    case CREATING_COURSE:
      return Object.assign({}, state, {
        isCreatingCourse: action.isCreatingCourse
      });
    case CREATED_COURSE:
      return Object.assign({}, state, {
        isCreatingCourse: action.isCreatingCourse,
        selectedCourse: action.course
      });
    case CREATING_ENROLMENT:
      return Object.assign({}, state, {
        isCreatingEnrolment: action.isCreatingEnrolment
      });
    case CREATED_ENROLMENT:
      return Object.assign({}, state, {
        isCreatingEnrolment: action.isCreatingEnrolment,
        selectedEnrolment: action.selectedEnrolment
      });
    case CREATING_LESSON:
      return Object.assign({}, state, {
        isCreatingLesson: action.isCreatingLesson
      });
    case CREATED_LESSON:
      return Object.assign({}, state, {
        isCreatingLesson: action.isCreatingLesson
      });
    case REQUEST_TEACHER_LESSONS:
      return Object.assign({}, state, {
        isLoadingTeacherLessons: action.isLoadingTeacherLessons
      });
    case RECEIVE_TEACHER_LESSONS:
      return Object.assign({}, state, {
        teacherLessons: {
          ...state.teacherLessons,
          [action.userIdStartEnd]: action.teacherLessons.Items
        },
        isLoadingTeacherLessons: action.isLoadingTeacherLessons,
        isLoadingData: action.isLoadingData
      });
    case REQUEST_TEACHER_AVAILABILITIES:
      return Object.assign({}, state, {
        isLoadingTeacherAvailabilities: action.isLoadingTeacherAvailabilities
      });
    case RECEIVE_TEACHER_AVAILABILITIES:
      return Object.assign({}, state, {
        teacherAvailabilities: Object.assign({}, state.teacherAvailabilities, {
          [action.userId]: action.teacherAvailabilities
        }),
        isLoadingTeacherAvailabilities: action.isLoadingTeacherAvailabilities
      });
    default:
      return state;
  }
}
