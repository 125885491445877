import { API } from "aws-amplify";
import * as Sentry from "@sentry/browser";
import config from "../../config";

Sentry.init(config.sentry);

export async function listPurchasesByUserId(userId) {
  try {
    return await API.get(
      "ilingu",
      `/finance/purchases/${userId}`,
      {}
    );
  } catch (e) {
    console.log(e);
    Sentry.captureException(e)
  }
}

export async function getBillingProfile(userId) {
  try {
    return await API.get(
      "ilingu",
      `/students/billing-profile/${userId}/`,
      {}
    );
  } catch (e) {
    console.log(e);
    Sentry.captureException(e)
  }
}

export async function getSources(stripeCustomerId) {
  try {
    return await API.get("ilingu", "/stripe/list-source", {
      queryStringParameters: {
        customerId: stripeCustomerId
      }
    });
  } catch (e) {
    console.log(e);
    Sentry.captureException(e)
  }
}

export async function attachSource(stripeCustomerId, sourceId) {
  try {
    return API.post(
      "ilingu",
      "/stripe/attach-source",
      { body:
          { customerId: stripeCustomerId,
            sourceId: sourceId,
        }
      }
      );
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

export async function deleteSource(stripeCustomerId, sourceId) {
  try {
    return API.del("ilingu", "/stripe/detach-source", {
      body: {
        customerId: stripeCustomerId,
        sourceId: sourceId
      }
    });
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

export async function changeDefaultSource(stripeCustomerId, sourceId) {
  try {
    return await API.put("ilingu", "/stripe/default-source", {
      body: {
        customerId: stripeCustomerId,
        sourceId: sourceId
      }
    });
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

export async function listExchangeRates() {
  try {
    return await API.get("ilingu", "/finance/exchange-rates", {})
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

export async function updateBillingProfile(userId, updateData) {
  try {
    return await API.put("ilingu", `/students/${userId}/billing-profile`, {
      body: updateData,
    })
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}