import { API } from "aws-amplify";
import { getUserId } from "./user";
import * as Sentry from "@sentry/browser";
import config from "../../config";

Sentry.init(config.sentry);

function LocalLessonException(message) {
  this.message = message;
  this.name = "Lesson Exception";
}

export async function createLesson(lesson) {
  if (!lesson.hasOwnProperty("enrolmentId")) {
    throw new LocalLessonException("No enrollment ID")
  } else if (!lesson.hasOwnProperty("courseId")) {
    throw new LocalLessonException("No course ID")
  } else if (!lesson.hasOwnProperty("teacherId")) {
    throw new LocalLessonException("No teacher ID")
  } else if (!lesson.hasOwnProperty("studentId")) {
    throw new LocalLessonException("No student ID")
  } else if (!lesson.hasOwnProperty("parts") || lesson.parts.length === 0) {
    throw new LocalLessonException("No lesson.parts")
  } else {
    try {
      return await API.post("ilingu", `/lessons/`, {
        body: lesson
      })
    } catch (e) {
      Sentry.captureException(e);
      console.log(e);
    }
  }
}

export async function listLessonsByStudentId(studentId) {
  try {
    return await API.get("ilingu", `/students/${studentId}/lessons`, {});
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

export async function listLessonsByTeacherId(teacherId, startDate, endDate) {
  try {
    return await API.get("ilingu", `/teachers/${teacherId}/lessons`, {
      queryStringParameters: {
        startDate: startDate,
        endDate: endDate
      }
    });
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}

// cancel lesson
export async function cancelLesson(lessonId) {
  const userId = await getUserId();
  try {
    return await API.del("ilingu", `/lessons/${lessonId}`, {
      body: {
        cancellingUserId: userId,
      }
    });
  } catch (e) {
    console.log(e);
    Sentry.captureException(e);
  }
}
