import * as Sentry from "@sentry/browser";
import config from "../../config";

import {
  ADDED_TRIAL_QUESTIONNAIRE,
  ADDING_TRIAL_QUESTIONNAIRE,
  CANCEL_LESSON_RETURN,
  CANCEL_LESSON_START,
  CREATED_LESSON,
  CREATING_LESSON,
  LIST_COURSES_BY_STUDENT_ID_RETURN,
  LIST_COURSES_BY_STUDENT_ID_START,
  LIST_ENROLMENTS_BY_STUDENT_ID_RETURN,
  LIST_ENROLMENTS_BY_STUDENT_ID_START,
  LIST_LESSONS_BY_STUDENT_ID_RETURN,
  LIST_LESSONS_BY_STUDENT_ID_START,
  RECEIVE_PRODUCTS,
  RECEIVE_TEACHER_LESSONS,
  RECEIVE_TEACHERS,
  REQUEST_PRODUCTS,
  REQUEST_TEACHER_LESSONS,
  REQUEST_TEACHERS,
  UPDATE_NOTIFICATION_PREFERENCES_RETURN,
  UPDATE_NOTIFICATION_PREFERENCES_START
} from "../action-types";
import { getErrorMessage } from "./messageInfo";
import { listEnrolmentsByStudentId as listEnrolmentsByStudentIdApi } from "../../libs/ilingu-lib/enrolment";
import { createTrailClassQuestionnaire } from "../../libs/ilingu-lib/trialClass";
import { listCoursesByStudentId as listCoursesByStudentIdApi } from "../../libs/ilingu-lib/course";
import { listProducts } from "../../libs/ilingu-lib/product";
import { listTeachers as listTeachersAPI } from "../../libs/ilingu-lib/teacher";
import { updateNotificationPreferencesAPI } from "../../libs/ilingu-lib/courses/updateNotificationPreferences";
import {
  cancelLesson as cancelLessonAPI,
  createLesson as createLessonAPI,
  listLessonsByStudentId as listLessonsByStudentIdApi,
  listLessonsByTeacherId
} from "../../libs/ilingu-lib/lesson";

const _ = require("lodash");

Sentry.init(config.sentry);

export const updateNotificationPreferencesStart = () => ({
  type: UPDATE_NOTIFICATION_PREFERENCES_START,
  isLoadingCourses: true
});
export const updateNotificationPreferencesReturn = response => ({
  type: UPDATE_NOTIFICATION_PREFERENCES_RETURN,
  isLoadingCourses: false,
  course: response.course
});
export function updateNotificationPreferences(
  courseId,
  notificationPreferences
) {
  return function(dispatch) {
    dispatch(updateNotificationPreferencesStart());

    return updateNotificationPreferencesAPI(courseId, notificationPreferences).then(
      response => dispatch(updateNotificationPreferencesReturn(response)),
      error => {
        Sentry.withScope(scope => {
          scope.setTag("file", "src/redux/actions/courseInfo.js:63");
          scope.setExtra("data", {
            courseId: courseId,
            notificationPreferences: notificationPreferences
          });
          Sentry.captureException(error);
        });
      }
    );
  };
}

export const listCoursesByStudentIdStart = () => ({
  type: LIST_COURSES_BY_STUDENT_ID_START,
  isLoadingCourses: true,
});
export const listCoursesByStudentIdReturn = courses => ({
  type: LIST_COURSES_BY_STUDENT_ID_RETURN,
  courses: courses,
  isLoadingCourses: false
});
export function listCoursesByStudentId(studentId) {
  return function(dispatch) {
    dispatch(listCoursesByStudentIdStart());

    return listCoursesByStudentIdApi(studentId).then(
      courses => dispatch(listCoursesByStudentIdReturn(courses)),
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}

export const listEnrolmentsByStudentIdStart = () => ({
  type: LIST_ENROLMENTS_BY_STUDENT_ID_START,
  isLoadingEnrolments: true
});
export const listEnrolmentsByStudentIdReturn = enrolments => ({
  type: LIST_ENROLMENTS_BY_STUDENT_ID_RETURN,
  enrolments: enrolments,
  isLoadingEnrolments: false
});
export function listEnrolmentsByStudentId(studentId) {
  return function(dispatch) {
    dispatch(listEnrolmentsByStudentIdStart());

    return listEnrolmentsByStudentIdApi(studentId).then(
      enrolments => dispatch(listEnrolmentsByStudentIdReturn(enrolments)),
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}

export const listLessonsByStudentIdStart = () => ({
  type: LIST_LESSONS_BY_STUDENT_ID_START,
  isLoadingLessons: true
});
export const listLessonsByStudentIdReturn = lessons => ({
  type: LIST_LESSONS_BY_STUDENT_ID_RETURN,
  lessons: lessons,
  isLoadingLessons: false
});
export function listLessonsByStudentId(studentId) {
  return function(dispatch) {
    dispatch(listLessonsByStudentIdStart());
    return listLessonsByStudentIdApi(studentId).then(
      lessons => dispatch(listLessonsByStudentIdReturn(lessons)),
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}

export const cancelLessonStart = () => ({
  type: CANCEL_LESSON_START,
  isLoadingCancelLesson: true
});
export const cancelLessonReturn = () => ({
  type: CANCEL_LESSON_RETURN,
  isLoadingCancelLesson: false
});
export function cancelLesson(lessonId) {
  return function(dispatch) {
    dispatch(cancelLessonStart());

    return cancelLessonAPI(lessonId).then(
      () => dispatch(cancelLessonReturn()),
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"));
      }
    );
  };
}

export const addingTrialQuestionnaire = () => ({
  type: ADDING_TRIAL_QUESTIONNAIRE,
  isAddingTrialQuestionnaire: true
});
export const addedTrialQuestionnaire = () => ({
  type: ADDED_TRIAL_QUESTIONNAIRE,
  isAddingTrialQuestionnaire: false,
  isAddedTrialQuestionnaire: true,
});
export function addTrialQuestionnaire(params) {
  return function(dispatch) {
    dispatch(addingTrialQuestionnaire());

    try {
      return createTrailClassQuestionnaire(params).then(() =>
        dispatch(addedTrialQuestionnaire())
      );
    } catch (e) {
      return createTrailClassQuestionnaire(params).then(
        () => dispatch(addedTrialQuestionnaire()),
        error => {
          Sentry.captureException(error);
          dispatch(getErrorMessage(error.message || "API Internal Error"));
        }
      );
    }
  };
}

export const requestProducts = () => ({
  type: REQUEST_PRODUCTS,
  isLoadingProducts: true,
  isLoadingData: true

});
export const receiveProducts = products => ({
  type: RECEIVE_PRODUCTS,
  products: products,
  // productMappingByProductId: _.keyBy(products, "productId"),
  isLoadingProducts: false,
  isLoadingData: false,
});
export function fetchProducts() {
  return function(dispatch) {
    dispatch(requestProducts());

    return listProducts().then(
      products => dispatch(receiveProducts(products)),
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"));
      }
    );
  };
}

export const requestTeachers = () => ({
  type: REQUEST_TEACHERS,
  isLoadingTeachers: true,
  isLoadingData: true
});
export const receiveTeachers = teachers => ({
  type: RECEIVE_TEACHERS,
  teachers: teachers,
  teacherMappingByUserId: _.keyBy(teachers, "userId"),
  isLoadingTeachers: false,
  isLoadingData: false
});
export function fetchTeachers() {
  return function(dispatch) {
    dispatch(requestTeachers());

    return listTeachersAPI().then(
      teachers => dispatch(receiveTeachers(teachers)), //TODO: pagination
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"));
      }
    );
  };
}

// export const creatingCourse = () => ({
//   type: CREATING_COURSE,
//   isCreatingCourse: true
// });
//
// export const createdCourse = course => ({
//   type: CREATED_COURSE,
//   isCreatingCourse: false,
//   selectedCourse: course
// });
// export function createCourse(params) {
//   return function(dispatch) {
//     dispatch(creatingCourse());
//
//     return createCourseAPI(params).then(
//       course => {
//         dispatch(createdCourse(course));
//         return course;
//       },
//       error => dispatch(getErrorMessage(error.message || "API Internal Error"))
//     );
//   };
// }
//
// export const creatingEnrolment = () => ({
//   type: CREATING_ENROLMENT,
//   isCreatingEnrolment: true
// });
//
// export const createdEnrolment = enrolment => ({
//   type: CREATED_ENROLMENT,
//   isCreatingEnrolment: false,
//   selectedEnrolment: enrolment
// });
// export function createEnrolment(params, currency) {
//   return function(dispatch) {
//     dispatch(creatingEnrolment());
//
//     return createEnrolmentAPI(params, currency).then(
//       enrolment => {
//         dispatch(createdEnrolment(enrolment));
//         return enrolment;
//       },
//       error => dispatch(getErrorMessage(error.message || "API Internal Error"))
//     );
//   };
// }
export const creatingLesson = () => ({
  type: CREATING_LESSON,
  isCreatingLesson: true
});
export const createdLesson = () => ({
  type: CREATED_LESSON,
  isCreatingLesson: false
});
export function createLesson(params) {
  return function(dispatch) {
    dispatch(creatingLesson());

    return createLessonAPI(params).then(
      () => dispatch(createdLesson()),
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}
export const fetchTeacherLessonsStart = () => ({
  type: REQUEST_TEACHER_LESSONS,
  isLoadingTeacherLessons: true,
  isLoadingData: true,
});
export const fetchTeacherLessonsReturn = (teacherLessons, userId, start, end) => ({
  type: RECEIVE_TEACHER_LESSONS,
  userIdStartEnd: `${userId}${start}${end}`,
  teacherLessons: teacherLessons,
  isLoadingTeacherLessons: false,
  isLoadingData: false,
});
export function fetchTeacherLessons(userId, start, end) {
  return function(dispatch) {
    dispatch(fetchTeacherLessonsStart());

    return listLessonsByTeacherId(userId, start, end).then(
      teacherLessons =>  {dispatch(fetchTeacherLessonsReturn(teacherLessons, userId, start, end))},
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}
