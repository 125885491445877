import React, { Fragment } from "react";
import { instanceOf, PropTypes } from "prop-types";
import { Cookies, withCookies } from "react-cookie";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
// core components
import Button from "../CustomButtons/Button.jsx";
import GridItem from "../Grid/GridItem";
import GridContainer from "../Grid/GridContainer";
// custom components
import LoginForm from "./LoginForm";
import ConfirmForm from "./ConfirmForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ForgotPasswordLongForm from "./ForgotPasswordLongForm";

import loginPageStyle from "../../assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimation: "cardHidden",
      display: "",
      email: "",
      password: ""
    };
  }
  displayNewComponent = componentName => {
    this.setState({
      display: componentName
    });
  };
  setEmail = event => {
    this.setState({
      email: event.target.value
    });
  };
  setPassword = event => {
    this.setState({
      password: event.target.value
    });
  };
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimation: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount() {
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  render() {
    const { display } = this.state;
    const { classes } = this.props;
    /**
     * If a user has never logged in before, they are the confirm attribute component
     *
     * If they forget their password they are shown the forgot password component
     *
     *
     */
    return (
      <Fragment>
        <Button
          id="login_button"
          round
          onClick={() => this.displayNewComponent("LoginForm")}
          color="primary"
          size="sm"
        >
          Login
        </Button>
        <Dialog
          open={this.state.display !== ""}
          onClose={() => this.setState({ display: "" })}
          aria-labelledby="form-dialog-title"
        >
          <DialogContent>
            {/*<p>{display}</p>*/}
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                  {display === "LoginForm" ? (
                      <LoginForm
                        classes={this.props.classes}
                        setEmail={this.setEmail}
                        email={this.state.email}
                        password={this.state.password}
                        setPassword={this.setPassword}
                        display={this.state.display}
                        displayNewComponent={this.displayNewComponent}
                        checkAuthState={this.props.checkAuthState}
                      />
                  ) : null}
                  {display === "ConfirmForm" ? (
                    <ConfirmForm
                      classes={this.props.classes}
                      email={this.state.email}
                      password={this.state.password}
                      display={this.state.display}
                      displayNewComponent={this.displayNewComponent}
                      checkAuthState={this.props.checkAuthState}
                    />
                  ) : null}
                  {display === "ForgotPasswordForm" ? (
                    <ForgotPasswordForm
                      classes={this.props.classes}
                      setEmail={this.setEmail}
                      email={this.state.email}
                      display={this.state.display}
                      displayNewComponent={this.displayNewComponent}
                      checkAuthState={this.props.checkAuthState}
                    />
                  ) : null}
                  {display === "ForgotPasswordLongForm" ? (
                    <ForgotPasswordLongForm
                      classes={this.props.classes}
                      email={this.state.email}
                      display={this.state.display}
                      displayNewComponent={this.displayNewComponent}
                      checkAuthState={this.props.checkAuthState}
                    />
                  ) : null}
                </GridItem>
              </GridContainer>
            </div>
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
  checkAuthState: PropTypes.func,
  cookies: instanceOf(Cookies).isRequired,
  history: PropTypes.object
};

export default withStyles(loginPageStyle)(withCookies(Login));
