/* eslint-disable */
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
import RegistrationForm from "../Auth/Registration";
import Login from "../Auth/Login";
import Signout from "../Auth/Signout";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Dvr from "@material-ui/icons/Dvr";
import Flight from "@material-ui/icons/Flight";
// import School from "@material-ui/icons/School";
// import PeopleOutline from "@material-ui/icons/PeopleOutline";
// import AssignmentInd from "@material-ui/icons/AssignmentInd";
import AccountBalance from "@material-ui/icons/AccountBalance";
import Email from "@material-ui/icons/Email";
import LocationCity from "@material-ui/icons/LocationCity";
import LocalLibrary from "@material-ui/icons/LocalLibrary";
// core components
import CustomDropdown from "../CustomDropdown/CustomDropdown.jsx";

import headerLinksStyle from "../../assets/jss/material-kit-pro-react/components/headerLinksStyle.jsx";

function HeaderLinks({ ...props }) {

  // const menuItemStyle = {
  //   fontSize: '13px',
  //   textAlign: 'center',
  //   display: 'flex',
  //   paddingTop: '0.75rem',
  //   paddingRight: '1.25rem',
  //   paddingBottom: '0.75rem',
  //   paddingLeft: '0.75rem',
  // };

  // const easeInOutQuad = (t, b, c, d) => {
  //   t /= d / 2;
  //   if (t < 1) return c / 2 * t * t + b;
  //   t--;
  //   return -c / 2 * (t * (t - 2) - 1) + b;
  // };

  // const smoothScroll = (e, target) => {
  //   if (window.location.pathname === "/sections") {
  //     const isMobile = navigator.userAgent.match(
  //       /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  //     );
  //     if (isMobile) {
  //       // if we are on mobile device the scroll into view will be managed by the browser
  //     } else {
  //       e.preventDefault();
  //       const targetScroll = document.getElementById(target);
  //       scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  //     }
  //   }
  // };
  // const scrollGo = (element, to, duration) => {
  //   let start = element.scrollTop,
  //     change = to - start,
  //     currentTime = 0,
  //     increment = 20;
  //
  //   const animateScroll = function() {
  //     currentTime += increment;
  //     element.scrollTop = easeInOutQuad(currentTime, start, change, duration);
  //     if (currentTime < duration) {
  //       setTimeout(animateScroll, increment);
  //     }
  //   };
  //   animateScroll();
  // };

  const { classes, dropdownHoverColor, checkAuthState, isAuthenticated } = props;

  return (
    <List className={classes.list + " " + classes.mlAuto}>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          navDropdown
          hoverColor={dropdownHoverColor}
          buttonText="Learn Chinese Online"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          buttonIcon={Dvr}
          dropdownList={[
            <Link to="/online" className={classes.dropdownLink}>
              <Dvr className={classes.dropdownIcons}/> Online Lessons
            </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem} id="uni_dropdown">
      <CustomDropdown
        noLiPadding
        navDropdown
        hoverColor={dropdownHoverColor}
        buttonText="Study in China"
        buttonProps={{
          className: classes.navLink,
          color: "transparent"
        }}
        buttonIcon={Flight}
        dropdownList={[
          <Link to="/bfsu" className={classes.dropdownLink} id="bfsu_link">
            <LocalLibrary className={classes.dropdownIcons} />Business School
          </Link>,
        ]}
      />
    </ListItem>
    <ListItem className={classes.listItem}>
      <CustomDropdown
        noLiPadding
        navDropdown
        hoverColor={dropdownHoverColor}
        buttonText="About Us"
        buttonProps={{
          className: classes.navLink,
          color: "transparent"
        }}
        buttonIcon={LocationCity}
        dropdownList={[
          <Link to="/our-school" className={classes.dropdownLink}>
              <AccountBalance className={classes.dropdownIcons} /> Our School
          </Link>,
          <Link to="/posts" className={classes.dropdownLink}>
            <Dvr className={classes.dropdownIcons} /> Blog
          </Link>,
          /*
          <Link to="/our-mission" className={classes.dropdownLink}>
            <PersonAdd className={classes.dropdownIcons} /> Our Mission
          </Link>,
           */
          <Link to="/contact" className={classes.dropdownLink}>
              <Email className={classes.dropdownIcons} /> Contact Info
          </Link>,
        ]}
      />
    </ListItem>
      { isAuthenticated ? (
        <ListItem className={classes.listItem} id="my_ilingu_dropdown">
          <CustomDropdown
            noLiPadding
            navDropdown
            dropdownHeader="Student Area"
            buttonText="My iLingu"
            buttonProps={{
              className: classes.navLink,
              color: "info",
              block: true,
            }}
            dropPlacement="bottom-start"
            hoverColor={"info"}
            dropdownList={[
              <Link to="/student/profile" className={classes.dropdownLink} >Profile</Link>,
              <Link
                to="/student/courses"
                className={classes.dropdownLink}
                data-cy="my_courses_menu_button"
              >
                My Courses
              </Link>,
              <Link to="/student/billing" className={classes.dropdownLink} >Billing</Link>,
              { divider: true },
              // <span
              //   onClick={() => props.toggleOlark()}
              //   // id="sign_out_span"
              //   style={menuItemStyle}
              // >
              //   Talk to us
              // </span>,
              { divider: true },
              <Signout />
            ]}
          />
        </ListItem>
      ) : (
        <ListItem className={classes.listItem}>
        <RegistrationForm checkAuthState={checkAuthState}/>
        <Login checkAuthState={checkAuthState} />
        </ListItem>
      )}
    </List>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary"
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose"
  ])
};

export default withStyles(headerLinksStyle)(HeaderLinks);
