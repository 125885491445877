import asyncComponent from "../components/Routes/AsyncComponent.js";

const home = asyncComponent(() => import("../views/PublicViews/iLinguHome/iLinguHome.jsx"));
const AsyncOnline = asyncComponent(() => import("../views/PublicViews/Online/Online.jsx"));
// const AsyncIntensive = lazy(() => import("views/PublicViews/Intensive/Intensive.jsx"));
// const AsyncRegular = lazy(() => import("views/PublicViews/Regular/Regular.jsx"));
// const AsyncPrivate = lazy(() => import("views/PublicViews/Private/Private.jsx"));
const AsyncVisa = asyncComponent(() => import("../views/PublicViews/Visa/Visa.jsx"));
const AsyncVisaApplication = asyncComponent(() => import("../views/PublicViews/Visa/VisaApplication.jsx"));
const AsyncBFSU = asyncComponent(() => import("../views/PublicViews/BFSU"));
const AsyncOurSchool = asyncComponent(() => import("../views/PublicViews/OurSchool/OurSchool.jsx"));
const AsyncOurMission = asyncComponent(() => import("../views/PublicViews/OurMission/OurMission.jsx"));
const AsyncContact = asyncComponent(() => import("../views/PublicViews/Contact/Contact.jsx"));
const AsyncTerms = asyncComponent(() => import("../views/PublicViews/Legal/TermsAndConditions/TermsAndConditions"));
const AsyncPostList = asyncComponent(() => import("../views/Blog/PostList.jsx"));
const AsyncSinglePost = asyncComponent(() => import("../views/Blog/SinglePost.jsx"));


const IndexRoutes = [
  // Courses
  { path: "/online", name: "Online", component: AsyncOnline },
  // { path: "/intensive", name: "Intensive", component: AsyncIntensive },
  // { path: "/regular", name: "Regular", component: AsyncRegular },
  // { path: "/private", name: "Private", component: AsyncPrivate },
  { path: "/visa", name: "Visa", component: AsyncVisa },
  { path: "/visa-application", name: "VisaApplication", component: AsyncVisaApplication },
  { path: "/bfsu", name: "BFSU", component: AsyncBFSU },
  // About Us
  { path: "/our-school", name: "OurSchool", component: AsyncOurSchool },
  { path: "/our-mission", name: "OurMission", component: AsyncOurMission },
  { path: "/contact", name: "Contact", component: AsyncContact },
  // Legal
  { path: "/terms", name: "Terms", component: AsyncTerms },
  // Blog
  { path: "/posts", name: "Blog", component: AsyncPostList },
  {
    path: "/post/:id",
    name: "SinglePost",
    component: AsyncSinglePost
  },
  // Home
  { path: "/", name: "iLinguHome", component: home },
];

export default IndexRoutes;
