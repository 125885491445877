import React, { Fragment } from "react";
import { instanceOf, PropTypes } from "prop-types";
import { Analytics, Auth } from "aws-amplify";
import { Cookies, withCookies } from "react-cookie";
import * as Sentry from "@sentry/browser";
import config from "../../config";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
// @material-ui/icons
import Code from "@material-ui/icons/Code";
// core components
import CustomInput from "../CustomInput/CustomInput.jsx";
import Button from "../CustomButtons/Button.jsx";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardFooter from "../Card/CardFooter.jsx";
import SweetAlert from "react-bootstrap-sweetalert";
import { CircularProgress } from "@material-ui/core";

import loginPageStyle from "../../assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";
import sweetAlertStyle from "../../assets/jss/material-kit-pro-react/views/sweetAlertStyle.jsx";
import Muted from "../Typography/Muted";

import { mailchimpSubscribeAPI } from "../../libs/ilingu-lib/user/helpers/mailchimpSubscribe";

Sentry.init(config.sentry);

const styles = {
  ...loginPageStyle,
  ...sweetAlertStyle
};

class ConfirmForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: null,
      cardAnimation: "cardHidden",
      confirmErrorMessage: "",
      confirmForm: {
        code: ""
      },
      open: false,
      isLoadingConfirmation: false
    };
  }
  flagIsLoadingConfirmation = flag => {
    this.setState({ isLoadingConfirmation: flag });
  };
  validateConfirmForm() {
    return this.state.confirmForm.code.length > 0;
  }
  handleConfirmChange = event => {
    const { confirmForm } = this.state;
    this.setState({
      confirmForm: {
        ...confirmForm,
        [event.target.id]: event.target.value
      }
    });
  };
  closeAndGoToLogin = async () => {
    try {
      await Analytics.updateEndpoint({
        address: this.props.email,
        channelType: "EMAIL",
        optOut: "NONE"
      });
      if (this.props.mailingList) {
        mailchimpSubscribeAPI(this.props.email);
      }
      window.gtag("event", "account_confirmed", {
        event_category: "auth",
        value: 5,
        send_to: config.googleAnalyticsCode
      });
      await Auth.signIn(this.props.email, this.props.password).then(() => {
        this.props.checkAuthState();
        this.props.displayNewComponent(""); // must be after other logic is completed or component will unmount and throw an error
      });
    } catch (e) {
      this.setState({
        confirmErrorMessage: e.message,
        isLoadingConfirmation: false
      });
    }
  };
  confirmUserSuccess() {
    this.setState({
      alert: (
        <SweetAlert
          success
          confirmBtnText="Login now"
          style={{ display: "block", marginTop: "-100px" }}
          onConfirm={async () => {
            this.closeAndGoToLogin()
          }}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
          <Muted>Your details have been confirmed and you can now login</Muted>
        </SweetAlert>
      )
    });
  }
  gtag_report_conversion(url) {
    let callback = function() {
      if (typeof url != "undefined") {
        window.location = url;
      }
    };
    window.gtag("event", "conversion", {
      send_to: "AW-792299903/CjLxCKaG3J4BEP-S5vkC",
      event_callback: callback
    });
    return false;
  }
  handleConfirm = async event => {
    event.preventDefault();
    this.flagIsLoadingConfirmation(true);
    try {
      await Auth.confirmSignUp(
        this.props.email.toLowerCase(),
        this.state.confirmForm.code
      ).then(async () => {
        this.flagIsLoadingConfirmation(false);
        if (process.env.REACT_APP_STAGE === "production") {
          this.gtag_report_conversion();
        }
        this.confirmUserSuccess();
      });
    } catch (e) {
      console.log("in catch now");
      console.log(e);
      this.setState({
        confirmErrorMessage: e.message,
        isLoadingConfirmation: false
      });
    }
  };
  resendConfirmCode = async () => {
    this.flagIsLoadingConfirmation(true);
    try {
      await Auth.resendSignUp(this.props.email).then(() => {
        console.log("code resent successfully");
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.flagIsLoadingConfirmation(false);
    }
  };
  render() {
    const { classes } = this.props;
    const { confirmErrorMessage, isLoadingConfirmation } = this.state;
    return (
      <Fragment>
        {this.state.alert}
        <form onSubmit={this.handleConfirm}>
          <Card className={classes[this.state.cardAnimation]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="rose"
            >
              <h4 className={classes.cardTitle}>Confirmation</h4>
              <p>
                A verification code has been sent to {this.props.email}.
                <br />
                Please input the code below to continue.
              </p>
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Code"
                id="code"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Code className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  onChange: this.handleConfirmChange,
                  value: this.state.confirmForm.code
                }}
              />
              {isLoadingConfirmation ? null : (
                <Button
                  // simple
                  onClick={this.resendConfirmCode}
                >
                  Re-send confirmation code
                </Button>
              )}
              <p>{confirmErrorMessage}</p>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              {isLoadingConfirmation ? (
                <CircularProgress />
              ) : (
                <Button
                  data-cy={"registration_flow_confirm_code_confirm_button"}
                  color="rose"
                  size="lg"
                  block
                  disabled={!this.validateConfirmForm()}
                  type="submit"
                >
                  Confirm
                </Button>
              )}
            </CardFooter>
          </Card>
        </form>
      </Fragment>
    );
  }
}

ConfirmForm.propTypes = {
  classes: PropTypes.object.isRequired,
  checkAuthState: PropTypes.func,
  cookies: instanceOf(Cookies).isRequired,
  history: PropTypes.object,
  email: PropTypes.string.isRequired
};

export default withStyles(styles)(withCookies(ConfirmForm));
