import React, { Fragment } from "react";
import { instanceOf, PropTypes } from "prop-types";
import { Auth } from "aws-amplify";
import { Cookies, withCookies } from "react-cookie";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";
import Tooltip from "@material-ui/core/Tooltip";
// @material-ui/icons
import Code from "@material-ui/icons/Code";
// core components
import CustomInput from "../CustomInput/CustomInput.jsx";
import Button from "../CustomButtons/Button.jsx";
import Card from "../Card/Card.jsx";
import CardBody from "../Card/CardBody.jsx";
import CardHeader from "../Card/CardHeader.jsx";
import CardFooter from "../Card/CardFooter.jsx";
import CircularProgress from "@material-ui/core/CircularProgress";
// styles
import loginPageStyle from "../../assets/jss/material-kit-pro-react/views/loginPageStyle.jsx";
import buttonStyle from "../../assets/jss/material-kit-pro-react/components/buttonStyle.jsx";

const styles = {
  ...loginPageStyle,
  ...buttonStyle,
};

class ForgotPasswordLongForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardAnimation: "cardHidden",
      errorMessage: "",
      forgotPasswordSubmitForm: {
        code: "",
        newPassword: "",
        newPasswordAgain: ""
      },
      isLoading: false,
    };
  }
  componentDidMount() {
    // we add a hidden class to the card and after 700 ms we delete it and the transition appears
    this.timeOutFunction = setTimeout(
      function() {
        this.setState({ cardAnimation: "" });
      }.bind(this),
      700
    );
  }
  componentWillUnmount(){
    clearTimeout(this.timeOutFunction);
    this.timeOutFunction = null;
  }
  handleForgotPasswordSubmitChange = event => {
    const { forgotPasswordSubmitForm } = this.state;
    this.setState({
      forgotPasswordSubmitForm: {
        ...forgotPasswordSubmitForm,
        [event.target.id]: event.target.value
      }
    });
  };
  validateForgotPasswordSubmitForm() {
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
    return (
      this.state.forgotPasswordSubmitForm.code.length > 0 &&
      this.state.forgotPasswordSubmitForm.newPassword.length > 0 &&
      passwordRegex.test(this.state.forgotPasswordSubmitForm.newPassword) &&
      this.state.forgotPasswordSubmitForm.newPassword === this.state.forgotPasswordSubmitForm.newPasswordAgain
    );
  }
  handleNewPasswordSubmit = async event => {
    event.preventDefault();
    this.setState({isLoading:true});
    try {
      await Auth.forgotPasswordSubmit(
        this.props.email.toLowerCase(),
        this.state.forgotPasswordSubmitForm.code,
        this.state.forgotPasswordSubmitForm.newPassword)
        .then(async () => {
          await Auth.signIn(
            this.props.email,
            this.state.forgotPasswordSubmitForm.newPassword,
          ).then(async () => {
            await this.props.checkAuthState();
            this.props.displayNewComponent("");
          })
        })
    } catch (e) {
      this.setState({
        forgotPasswordSubmitErrorMessage: e.message
      });
    } finally {
      this.setState({isLoading:false})
    }
  };
  render() {
    const { classes } = this.props;
    const {
      errorMessage,
      isLoading
      // forgotPasswordSubmitForm,
    } = this.state;
    return (
      <Fragment>
        <form onSubmit={this.handleNewPasswordSubmit}>
          <Card className={classes[this.state.cardAnimation]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="rose"
            >
              <h4 className={classes.cardTitle}>Reset Password</h4>
            </CardHeader>
            <CardBody>
              <CustomInput
                labelText="Code"
                id="code"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Code className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                  onChange: this.handleForgotPasswordSubmitChange,
                  value: this.state.forgotPasswordSubmitForm.code
                }}
              />
              <CustomInput
                labelText="New Password"
                id="newPassword"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                      </Icon>
                    </InputAdornment>
                  ),
                  type: "password",
                  onChange: this.handleForgotPasswordSubmitChange,
                  value: this.state.forgotPasswordSubmitForm.newPassword
                }}
              />
              <CustomInput
                labelText="New Password Again"
                id="newPasswordAgain"
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Icon className={classes.inputAdornmentIcon}>
                        lock_outline
                      </Icon>
                    </InputAdornment>
                  ),
                  type: "password",
                  onChange: this.handleForgotPasswordSubmitChange,
                  value: this.state.forgotPasswordSubmitForm.newPasswordAgain
                }}
              />
              <p>{errorMessage}</p>
            </CardBody>
            <Tooltip
              title="Password must be longer than 8 characters,
                  and must contain at least one upper case letter,
                  one lower case letter, and one number."
              placement="top"
            >
              <CardFooter className={classes.justifyContentCenter}>
                <span>
                  <Button
                    color="rose"
                    simple
                    size="lg"
                    block
                    disabled={(
                      !this.validateForgotPasswordSubmitForm()
                      || isLoading
                    )}
                    type="submit"
                  >
                    {isLoading ? <CircularProgress /> : "Submit"}
                  </Button>
                </span>
              </CardFooter>
            </Tooltip>
          </Card>
        </form>
      </Fragment>
    );
  }
}

ForgotPasswordLongForm.propTypes = {
  classes: PropTypes.object.isRequired,
  checkAuthState: PropTypes.func,
  cookies: instanceOf(Cookies).isRequired,
  history: PropTypes.object
};

export default withStyles(styles)(withCookies(ForgotPasswordLongForm));
