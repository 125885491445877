// core libraries
import React, { Fragment } from "react";
import connect from "react-redux/es/connect/connect";
import { Auth } from "aws-amplify";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import config from "../../config";
// material-ui and kit components
import withStyles from "@material-ui/core/styles/withStyles";
// import SyntaxHighlighter from 'react-syntax-highlighter/prism';
// import { prism } from 'react-syntax-highlighter/styles/prism';
// material-ui components
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import customSelectStyles from "../../assets/jss/material-kit-pro-react/customSelectStyle.jsx";
// redux actions
import { setDisplayCurrency } from "../../redux/actions/userInfo";
import { updateBillingProfile } from "../../redux/actions/financeInfo";

Sentry.init(config.sentry);


/**
 * Component intention
 *
 * 1. If student has preferredCurrency and preferred currency is in enabled
 * currencies then set that as the display currency for the website
 *
 * 2. If student does not have preferred currency try to use the currency from IP stack
 * If that's not in available curencies, then default to ...
 *
 * 3. Default to GBP
 *
 */

class CurrencySwitcher extends React.Component {
  // constructor(props) {
  //   super(props);
  // }
  // componentDidMount() {
  //   const { financeInfo, userInfo } = this.props;
  // }
  setDisplayCurrency = (event) => {
    let newDisplayCurrency = event.target.value;
    this.props.setDisplayCurrency(newDisplayCurrency);
    try {
      Auth.currentSession().then((data) => {
          // console.log(data);
          let userId = data.idToken.payload.sub;
          this.props.updateBillingProfile(userId, {preferredCurrency: newDisplayCurrency})
        }
      )
    } catch (e) {
      console.log(e);
      Sentry.captureMessage("Inside Currency Switcher");
      Sentry.captureException(e);
    }
  };

  render() {
    const {
      classes,
      userInfo,
      financeInfo,
    } = this.props;
    let rateCodesArray = Object.keys(financeInfo.exchangeRates.rates);
    const options = rateCodesArray.map(rateCode => {
      let text;
      if (this.props.longVersion) {
        text = financeInfo.currenciesByCode[rateCode].currency_name_formatted;
      // } else if (this.props.shortVersion) {
      //   if (rateCode === "NZD") {
      //     text = "NZD$"
      //   } else {
      //     text = financeInfo.currenciesByCode[rateCode].currency_symbol;
      //   }
      } else {
        text = rateCode
      }
      return (
        <MenuItem
          key={`${rateCode}-select-option`}
          classes={{
            root: classes.selectMenuItem,
            selected: classes.selectMenuItemSelected
          }}
          value={rateCode}>
          {text}
        </MenuItem>
      )
    });
    return (
      <Fragment>
        <FormControl
          fullWidth
          className={classes.selectFormControl}
        >
            {this.props.longVersion ? (
              <InputLabel
                htmlFor="simple-select"
                className={classes.selectLabel}
              >
                Select Preferred Currency
              </InputLabel>
            ) : (
              this.props.shortVersion ? null : (
                <InputLabel
                  htmlFor="simple-select"
                  className={classes.selectLabel}
                >
                  Currency
                </InputLabel>)
            )}
          <Select
            MenuProps={{
              className: classes.selectMenu
            }}
            classes={{
              select: classes.select
            }}
            value={userInfo.displayCurrency}
            onChange={(event) => this.setDisplayCurrency(event)}
            inputProps={{
              name: "simpleSelect",
              id: "simple-select"
            }}
          >
            {options}
          </Select>
        </FormControl>
      </Fragment>
    );
  }
}

CurrencySwitcher.propTypes = {
  classes: PropTypes.object.isRequired,
  userInfo: PropTypes.object,
  financeInfo: PropTypes.object,
  longVersion: PropTypes.bool,
  shortVersion: PropTypes.bool,
};

const mapStateToProps = state => {
  return {
    userInfo: state.userInfo,
    financeInfo: state.financeInfo
  };
};

export default connect(
  mapStateToProps,
  {
    setDisplayCurrency,
    updateBillingProfile,
  }
)(withStyles(customSelectStyles)(CurrencySwitcher));
