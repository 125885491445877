import {
  ATTACHED_SOURCE,
  ATTACHING_SOURCE,
  CHANGED_DEFAULT_SOURCE,
  CHANGING_DEFAULT_SOURCE,
  DELETED_SOURCE,
  DELETING_SOURCE,
  LIST_EXCHANGE_RATES_RETURN,
  LIST_EXCHANGE_RATES_START,
  RECEIVE_BILLING_PROFILE,
  RECEIVE_SOURCES,
  REQUEST_BILLING_PROFILE,
  REQUEST_PURCHASES_RETURN,
  REQUEST_PURCHASES_START,
  REQUEST_SOURCES,
  UPDATE_BILLING_PROFILE_RETURN,
  UPDATE_BILLING_PROFILE_START
} from "../action-types";

export default function getFinanceData(
  state = {
    billingProfile: {},
    isLoadingSources: false,
    isLoadingBillingProfile: false,
    isLoadingPurchases: false,
    isRenewingEnrolment: false,
    purchases: [],
    sources: [],
    exchangeRates: {
      rates: {}
    },
    currencies: [],
  },
  action
) {
  switch (action.type) {
    case LIST_EXCHANGE_RATES_START:
      return Object.assign({}, state, {
        isLoadingExchangeRates: action.isLoadingExchangeRates,
      });
    case LIST_EXCHANGE_RATES_RETURN:
      let currenciesByCode = {};
      action.currencies.forEach(currency => {
        currenciesByCode = {
          ...currenciesByCode,
          [currency.zohoCurrencyObject.currency_code]: currency.zohoCurrencyObject,
        }
      });
      return Object.assign({}, state, {
        isLoadingExchangeRates: action.isLoadingExchangeRates,
        exchangeRates: action.exchangeRates,
        currencies: action.currencies,
        currenciesByCode: currenciesByCode,
      });
    case REQUEST_PURCHASES_START:
      return Object.assign({}, state, {
        isLoadingPurchases: action.isLoadingPurchases,
      });
    case REQUEST_PURCHASES_RETURN:
      let purchasesById = {};
      action.purchases.forEach(purchase => {
        purchasesById = {
          ...purchasesById,
          [purchase.enrolmentId]: purchase,
        }
      });
      return Object.assign({}, state, {
        isLoadingPurchases: action.isLoadingPurchases,
        purchases: action.purchases,
        purchasesById: purchasesById,
      });
    case REQUEST_BILLING_PROFILE:
      return Object.assign({}, state, {
        isLoadingBillingProfile: action.isLoadingBillingProfile
      });
    case RECEIVE_BILLING_PROFILE:
      return Object.assign({}, state, {
        isLoadingBillingProfile: action.isLoadingBillingProfile,
        billingProfile: action.billingProfile
      });
    case UPDATE_BILLING_PROFILE_START:
      return Object.assign({}, state, {
        isLoadingBillingProfile: action.isLoadingBillingProfile,
      });
    case UPDATE_BILLING_PROFILE_RETURN:
      return Object.assign({}, state, {
        isLoadingBillingProfile: action.isLoadingBillingProfile,
        billingProfile: action.billingProfile,
      });
    case REQUEST_SOURCES:
      return Object.assign({}, state, {
        isLoadingSources: action.isLoadingSources
      });
    case RECEIVE_SOURCES:
      return Object.assign({}, state, {
        sources: action.sources,
        isLoadingSources: action.isLoadingSources
      });
    case ATTACHING_SOURCE:
    case ATTACHED_SOURCE:
      return Object.assign({}, state, {
        isAttachingSource: action.isAttachingSource
      });
    case DELETING_SOURCE:
      return Object.assign({}, state, {
        deletingSourceId: action.deletingSourceId
      });
    case DELETED_SOURCE:
      return Object.assign({}, state, {
        deletingSourceId: undefined
      });
    case CHANGING_DEFAULT_SOURCE:
      return Object.assign({}, state, {
        changingToDefaultSourceId: action.changingToDefaultSourceId
      });
    case CHANGED_DEFAULT_SOURCE:
      return Object.assign({}, state, {
        changingToDefaultSourceId: undefined
      });
    default:
      return state;
  }
}
