import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import userInfo from "./userInfo";
import messageInfo from "./messageInfo";
import financeInfo from "./financeInfo";
import courseInfo from "./courseInfo";

export default history =>
  combineReducers({
    userInfo,
    messageInfo,
    financeInfo,
    courseInfo,
    router: connectRouter(history)
  });
