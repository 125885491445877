import * as Sentry from "@sentry/browser";
import config from "../../config";

import {
  ADD_ADDRESS_RETURN,
  ADD_ADDRESS_START,
  CHANGED_DEFAULT_ADDRESSES,
  CHANGING_DEFAULT_ADDRESSES,
  GET_IP_STACK_DATA_RETURN,
  GET_IP_STACK_DATA_START,
  RECEIVE_ADDRESSES,
  RECEIVE_STUDENT,
  REQUEST_ADDRESSES,
  REQUEST_STUDENT,
  SET_DISPLAY_CURRENCY,
  UPDATE_STUDENT_FULL_RETURN,
  UPDATE_STUDENT_FULL_START,
  UPDATE_STUDENT_SECTION_RETURN,
  UPDATE_STUDENT_SECTION_START,
  UPDATE_TIMEZONE,
  UPDATED_TIMEZONE
} from "../action-types";

import { getErrorMessage } from "./messageInfo";

import { updateZohoBooksContact as updateZohoBooksContactAPI } from "../../libs/ilingu-lib/user";
import {
  changeDefaultAddress as changeDefaultAddressAPI,
  createAddress as createAddressAPI,
  listAddresses
} from "../../libs/ilingu-lib/address";
import { getStudentFromUser, updateStudent, updateStudentFull as USFRequest } from "../../libs/ilingu-lib/student";

import { getIpStackData as getIpStackDataAPI } from "../../libs/ilingu-lib/user/helpers/getIpStackData";

Sentry.init(config.sentry);

export const setDisplayCurrency = (newDisplayCurrency) => ({
  type: SET_DISPLAY_CURRENCY,
  displayCurrency: newDisplayCurrency,
});

export const getIpStackDataStart = () => ({
  type: GET_IP_STACK_DATA_START,
  isLoadingIpStackData: true,
});
export const getIpStackDataReturn = (data) => ({
  type: GET_IP_STACK_DATA_RETURN,
  isLoadingIpStackData: false,
  ipStackData: data
});
export function getIpStackData() {
  return async function(dispatch) {
    dispatch(getIpStackDataStart());

    return getIpStackDataAPI().then(
      response => {dispatch(getIpStackDataReturn(response))},
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Error"))
      }
    )
  }
}


// update student timezone
export const updateTimezoneStart = () => ({
  type: UPDATE_TIMEZONE,
  isUpdatingTimezone: true,
});

export const updateTimezoneReturn = data => ({
  type: UPDATED_TIMEZONE,
  isUpdatingTimezone: false,
  student: data,
});

export function updateStudentTimezone(userId, studentTimezone) {
  return async function(dispatch) {
    dispatch(updateTimezoneStart());

    return updateStudent(userId, { studentTimezone: studentTimezone })
      .then(
        response => {dispatch(updateTimezoneReturn(response))},
        error => {
          Sentry.captureException(error);
          dispatch(getErrorMessage(error.message || "API Internal Error"))
        }
      )
  };
}

// update student timezone
export const updateStudentSectionStart = () => ({
  type: UPDATE_STUDENT_SECTION_START,
  isLoadingUpdateStudent: true,
});

export const updateStudentSectionReturn = data => ({
  type: UPDATE_STUDENT_SECTION_RETURN,
  isLoadingUpdateStudent: false,
  student: data,
});

export function updateStudentSection(userId, updateData) {
  return async function(dispatch) {
    dispatch(updateStudentSectionStart());

    return await updateStudent(userId, updateData)
      .then(
        response => {dispatch(updateStudentSectionReturn(response))},
        error => {
          Sentry.captureException(error);
          dispatch(getErrorMessage(error.message || "API Internal Error"))
        }
      )
  };
}

// update student profile (sending full profile object)
export const updateStudentProfileFullStart = () => ({
  type: UPDATE_STUDENT_FULL_START,
  isLoadingUpdateStudent: true,
});

export const updateStudentProfileFullReturn = student => ({
  type: UPDATE_STUDENT_FULL_RETURN,
  isLoadingUpdateStudent: false,
  student: student,
});

export function updateStudentFull(studentProfile) {
  return async function(dispatch) {
    dispatch(updateStudentProfileFullStart());

    return USFRequest(studentProfile)
      .then(
        response => {dispatch(updateStudentProfileFullReturn(response))},
        error => {
          Sentry.captureException(error);
          dispatch(getErrorMessage(error.message || "API Internal Error"))
        }
      )
  };
}

// fetch student
export const requestStudent = () => ({
  type: REQUEST_STUDENT,
  isLoadingStudent: true
});

export const receiveStudent = data => ({
  type: RECEIVE_STUDENT,
  student: data,
  isLoadingStudent: false
});

export function fetchStudent() {
  return function(dispatch) {
    dispatch(requestStudent());

    return getStudentFromUser().then(
      response => dispatch(receiveStudent(response)),
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}

// fetch Address
export const requestAddresses = () => ({
  type: REQUEST_ADDRESSES,
  isLoadingAddresses: true
});

export const receiveAddresses = addresses => ({
  type: RECEIVE_ADDRESSES,
  addresses: addresses,
  isLoadingAddresses: false
});

export function fetchAddresses(userId) {
  return function(dispatch) {
    dispatch(requestAddresses());

    return listAddresses(userId).then(
      addresses => dispatch(receiveAddresses(addresses)),
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}

// change Default Address
export const changingDefaultAddress = addressId => ({
  type: CHANGING_DEFAULT_ADDRESSES,
  changingToDefaultAddressId: addressId
});

export const changedDefaultAddress = () => ({
  type: CHANGED_DEFAULT_ADDRESSES
});

export function changeDefaultAddress(userId, addressId) {
  return function(dispatch) {
    dispatch(changingDefaultAddress(addressId));

    return changeDefaultAddressAPI(userId, addressId).then(
      () => {
        dispatch(changedDefaultAddress());
        dispatch(fetchAddresses(userId));
      },
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}

// add Address
export const addAddressStart = () => ({
  type: ADD_ADDRESS_START,
  isAddingAddress: true
});

export const addAddressReturn = () => ({
  type: ADD_ADDRESS_RETURN,
  isAddingAddress: false
});

export function addAddress(userId, address) {
  return function(dispatch) {
    dispatch(addAddressStart());

    return createAddressAPI(userId, address).then(
      () => {
        dispatch(addAddressReturn());
        dispatch(fetchAddresses(userId));
      },
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}

// update Zoho Books Contact
export function updateZohoBooksContact(zohoContactID, student, address) {
  return function(dispatch) {
    return updateZohoBooksContactAPI(zohoContactID, student, address).then(
      () => {},
      error => {
        Sentry.captureException(error);
        dispatch(getErrorMessage(error.message || "API Internal Error"))
      }
    );
  };
}
